export type NodeId = string;
export type EdgeId = string;
export type ServiceUrn = string;
export type PropertyId = string;
export type PropertyType = 'string' | 'number' | 'boolean' | 'object' | 'array' | 'null';
export type OriginId = EdgeId | NodeId | ServiceUrn;

export type Path = {
  nodes: NodeId[];
  edges: EdgeId[];
};

export type PropertyOrigin = {
  id: OriginId;
  label?: string;
  kind: 'edge' | 'task' | 'vertice task' | 'workflow input' | 'udf';
};

type PropertyBase = {
  /** Unique identifier of the property with dot notation for nested properties */
  id: PropertyId;
  /** Human-readable label of the property */
  label: string;
  /** Primary type of property based on which the operators and available values are determined in the expression builder */
  xType?: string;
  /** Secondary type of property */
  type?: PropertyType[];
  /** Format of the property. i.e. date */
  format?: string;
  /** Human-readable type */
  typeLabel?: string[];
  /** Primary origin of the property (from which place in the workflow has the property been written) */
  origin: PropertyOrigin;
  /** Whether the property should be available for the user to work with */
  isVisible: boolean;
};

export type SimpleProperty = PropertyBase;

export type ExtendedProperty = PropertyBase & {
  /** Whether the property is required (applicable only for properties from tasks) */
  required: boolean;
  /** List of nested properties (applicable only for properties with type 'object' or 'array') */
  properties: Property[];
};

export type Property = ExtendedProperty | SimpleProperty;

export const isExtendedProperty = (property: Property): property is ExtendedProperty => 'properties' in property;
