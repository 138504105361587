import { Task } from '../../../definitionsTypes';
import { isVerticeServiceConfiguration } from '../../../definitions/taskDefinition';
import { getLastPartDiversioned } from '../../../utils';

const EDITABLE_SERVICE_CONFIGURATIONS = ['service/jira/createTicket', 'service/jira/syncTicket'];

/**
 * Among its configurations, there is some which can be configured via editable drawer
 */
export const checkServiceTaskIsEditable = (task: Task) => {
  return task.configurations
    ? task.configurations.some((conf) => {
        if (isVerticeServiceConfiguration(conf)) {
          const serviceType = getLastPartDiversioned(conf.resourceUrn);
          return serviceType && EDITABLE_SERVICE_CONFIGURATIONS.includes(serviceType);
        }
        return false;
      })
    : false;
};
