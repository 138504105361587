/**
 * @file
 * Currently, we support just one hardcoded form.
 * To be replaced with dynamic form rendering.
 */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RadioGroup, Stack } from '@mui/material';
import { useController } from 'react-hook-form';

import { RadioSwitch } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { FormData } from './schema';
import { useTaskContext } from '../../../TaskContext';
import { TaskFormControlLabel } from '../shared/formFields/TaskFormControlLabel';
import { SavingsInsightsFormEntry } from '../shared/formFields/SavingsInsightsFormEntry';

export const ApprovalSimpleForm = () => {
  const { t } = useTranslation();
  const {
    field: { ref, value, ...field },
  } = useController<FormData>({ name: 'approved' });
  const { taskOverview } = useTaskContext();

  const buttonLabels = taskOverview?.task.input.buttonLabels;
  const formHeading = taskOverview?.task.input.formHeading;

  const handleChange = (newValue: string) => field.onChange(newValue === 'true');

  return (
    <Stack gap={6}>
      <Stack sx={{ wordWrap: 'break-word', width: '100%', alignItems: 'center' }}>
        <Text variant="body-regular" size="M">
          {formHeading ?? (
            <Trans
              i18nKey={'INTELLIGENT_WORKFLOWS.TASK_MODAL.WHAT_IS_THE_FINAL_DECISION'}
              components={{ bold: <b /> }}
              values={{ taskName: taskOverview?.task.name }}
            />
          )}
        </Text>
      </Stack>
      <RadioGroup ref={ref} value={value} onChange={(_event, newValue) => handleChange(newValue)}>
        <TaskFormControlLabel
          control={<RadioSwitch value="true" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={buttonLabels?.approve ?? t('INTELLIGENT_WORKFLOWS.TASK_MODAL.APPROVED')}
        />
        <TaskFormControlLabel
          control={<RadioSwitch value="false" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={buttonLabels?.reject ?? t('INTELLIGENT_WORKFLOWS.TASK_MODAL.DECLINED')}
        />
      </RadioGroup>
      <SavingsInsightsFormEntry name="approvalNotes" />
    </Stack>
  );
};
