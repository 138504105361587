import { createContext, useState, useContext, PropsWithChildren } from 'react';

export type ShoppingCartItemType = {
  id: string;
  executionDate?: string;
};

type ShoppingCartContextType = {
  cartItems: ShoppingCartItemType[];
  addToCart: (item: ShoppingCartItemType) => void;
  updateCart: (item: ShoppingCartItemType) => void;
  removeFromCart: (id: string) => void;
  filterByShoppingCart: boolean;
  setFilterByShoppingCart: (value: boolean) => void;
};

const ShoppingCartContext = createContext<ShoppingCartContextType>({} as ShoppingCartContextType);

export const ShoppingCartProvider = ({ children }: PropsWithChildren) => {
  const [cartItems, setCartItems] = useState<ShoppingCartItemType[]>([]);
  const [filterByShoppingCart, setFilterByShoppingCart] = useState<boolean>(false);

  const addToCart = (item: ShoppingCartItemType) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((prevItem) => prevItem.id === item.id);

      if (existingItem) {
        return prevItems.map((prevItem) =>
          prevItem.id === item.id
            ? {
                ...prevItem,
                executionDate: item.executionDate || prevItem.executionDate,
              }
            : prevItem
        );
      } else {
        // add new item to cart
        return [...prevItems, item];
      }
    });
  };

  const updateCart = (item: ShoppingCartItemType) => {
    setCartItems((prevItems) => prevItems.map((prevItem) => (prevItem.id === item.id ? item : prevItem)));
  };

  const removeFromCart = (id: string) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateCart,
        removeFromCart,
        filterByShoppingCart,
        setFilterByShoppingCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => useContext(ShoppingCartContext);
