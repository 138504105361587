import type { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import type { Property, PropertyOrigin, PropertyType, ServiceUrn } from '../WorkflowEditor/types';
import type { JsonSchema, Schema } from './types';

export const isUDFService = (kind: any) =>
  typeof kind === 'string' && kind === 'Vertice/ServiceCatalog/Function/FunctionDefinition';

export const isVerticeService = (kind: any) =>
  typeof kind === 'string' && kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition';

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const formatPropertyLabel = (propertyName: string): string => {
  const parts = propertyName.split(/(?=[A-Z])/);
  return parts.map(capitalizeFirstLetter).join(' ');
};

const isUdfPropertyId = (propertyId: string) => propertyId.startsWith('udf');

export const getPropertyId = (propertyName: string, parentPropertyId?: string): string => {
  if (!parentPropertyId) {
    return propertyName;
  }

  if (isUdfPropertyId(parentPropertyId)) {
    return `(${parentPropertyId}).${propertyName}`;
  }

  return `${parentPropertyId}.${propertyName}`;
};

const PROPERTY_XTYPE_MAP = {
  requestId: {
    label: 'Request ID',
    regex: /urn:verticeone:vertice::services:schema\/core\/request\/id\/v\d+$/,
    isVisible: false,
  },
  departmentId: {
    label: 'Department ID',
    regex: /urn:verticeone:vertice::services:schema\/core\/department\/id\/v\d+$/,
    isVisible: true,
  },
  vendor: {
    label: 'Vendor',
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/v\d+$/,
    isVisible: true,
  },
  vendorId: {
    label: 'Vendor ID',
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/id\/v\d+$/,
    isVisible: false,
  },
  product: {
    label: 'Product',
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/v\d+$/,
    isVisible: true,
  },
  productId: {
    label: 'Product ID',
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/id\/v\d+$/,
    isVisible: false,
  },
  contractId: {
    label: 'Contract ID',
    regex: /urn:verticeone:vertice::services:schema\/saas\/contract\/id\/v\d+$/,
    isVisible: false,
  },
  userId: {
    label: 'User ID',
    regex: /urn:verticeone:vertice::services:schema\/core\/user\/id\/v\d+$/,
    isVisible: false,
  },
  accountId: {
    label: 'Account ID',
    regex: /urn:verticeone:vertice::services:schema\/core\/account\/id\/v\d+$/,
    isVisible: false,
  },
  money: {
    label: 'Money',
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/v\d+$/,
    isVisible: true,
  },
  moneyAmount: {
    label: 'Amount',
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/amount\/v\d+$/,
    isVisible: true,
  },
  moneyCurrency: {
    label: 'Currency',
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/currency\/v\d+$/,
    isVisible: true,
  },
} as const;

export const formatPropertyType = ({
  propertyType = [],
  format,
  xType,
  isEnum,
}: {
  propertyType?: PropertyType[];
  xType?: string;
  format?: string;
  isEnum?: boolean;
}) => {
  if (xType) {
    const label = Object.entries(PROPERTY_XTYPE_MAP).find(([_, { regex }]) => regex.test(xType))?.[1]?.label;
    if (label) {
      return [label];
    }
  }

  if (isEnum) {
    return ['Enum'];
  }

  if (format) {
    return [capitalizeFirstLetter(format)];
  }

  return propertyType.map(capitalizeFirstLetter);
};

const getPropertyTypes = (schema: JsonSchema): PropertyType[] => {
  if (schema.type) {
    return Array.isArray(schema.type) ? schema.type : [schema.type];
  }

  return [];
};

const getIsPropertyVisible = ({ xType, propertyName }: { propertyName: string; xType?: string }) => {
  if (propertyName === 'id') {
    return false;
  }

  if (!xType) {
    return true;
  }

  const xTypeDefinition = Object.entries(PROPERTY_XTYPE_MAP).find(([_, { regex }]) => regex.test(xType))?.[1];

  if (!xTypeDefinition) {
    return true;
  }

  return xTypeDefinition.isVisible;
};

const removeEmptyObjectAndArrayProperties = (property: Property) => {
  if (
    (property.type?.includes('object') || property.type?.includes('array')) &&
    (!('properties' in property) || !property.properties.length)
  ) {
    return false;
  }

  return true;
};

export const convertJsonSchemaToSchema = ({
  jsonSchema,
  origin,
  parentPropertyId,
  parentPropertyLabel,
  parentPropertyType,
}: {
  jsonSchema: JsonSchema;
  origin: PropertyOrigin;
  parentPropertyId?: string;
  parentPropertyLabel?: string;
  parentPropertyType?: PropertyType;
}): Schema => {
  if (parentPropertyType === 'array' && parentPropertyId) {
    const propertyId = (isUdfPropertyId(parentPropertyId) ? `(${parentPropertyId})` : parentPropertyId) + '[0]';
    const propertyType = getPropertyTypes(jsonSchema);
    const typeLabel = formatPropertyType({
      propertyType,
      format: jsonSchema.format,
      xType: jsonSchema['x-type'],
      isEnum: !!jsonSchema.enum,
    });
    const propertyLabel = parentPropertyLabel ? `${parentPropertyLabel} - Item` : 'Item';

    return {
      properties: [
        {
          id: propertyId,
          label: propertyLabel,
          type: propertyType,
          xType: jsonSchema['x-type'],
          format: jsonSchema.format,
          isVisible: getIsPropertyVisible({
            xType: jsonSchema['x-type'],
            propertyName: propertyId,
          }),
          typeLabel: typeLabel,
          origin,
          properties: jsonSchema.properties
            ? convertPropertiesToSchema({
                properties: jsonSchema.properties,
                origin,
                parentPropertyId: propertyId,
              }).properties
            : getNextPropertiesParsingStep({
                propertyType: propertyType[0],
                propertyLabel,
                propertyId,
                origin,
                jsonSchema,
              }),
        },
      ],
    };
  }

  if (!jsonSchema.properties) {
    return { properties: [] };
  }

  return convertPropertiesToSchema({
    properties: jsonSchema.properties,
    origin,
    parentPropertyId,
    parentRequired: jsonSchema.required,
  });
};

const convertPropertiesToSchema = ({
  properties,
  origin,
  parentPropertyId,
  parentRequired,
}: {
  properties: Record<string, JsonSchema>;
  origin: PropertyOrigin;
  parentPropertyId?: string;
  parentRequired?: string[];
}): Schema => {
  return {
    properties: Object.entries(properties)
      .map(([name, jsonSchema]) => {
        const propertyType = getPropertyTypes(jsonSchema);
        const propertyId = getPropertyId(name, parentPropertyId);
        const propertyLabel = jsonSchema.title || formatPropertyLabel(name);
        const typeLabel = formatPropertyType({
          propertyType,
          format: jsonSchema.format,
          xType: jsonSchema['x-type'],
          isEnum: !!jsonSchema.enum,
        });

        return {
          id: propertyId,
          label: propertyLabel,
          type: propertyType,
          xType: jsonSchema['x-type'],
          format: jsonSchema.format,
          isVisible: getIsPropertyVisible({
            xType: jsonSchema['x-type'],
            propertyName: name,
          }),
          typeLabel,
          origin,
          required: parentRequired?.includes(name) || false,
          properties: getNextPropertiesParsingStep({
            propertyType: propertyType[0],
            propertyLabel,
            propertyId,
            origin,
            jsonSchema,
          }),
        };
      })
      .filter((property) => property.isVisible)
      .filter(removeEmptyObjectAndArrayProperties),
  };
};

const getUdfInputFromInputJsonSchema = (service: ServiceCatalogResource): string => {
  const inputJsonSchema = service.definition?.FunctionProvider?.Interface?.Input?.JsonSchema;

  if (!inputJsonSchema) {
    return '';
  }

  if (inputJsonSchema.type === 'object' && inputJsonSchema.properties) {
    let udfInput = '{';

    Object.keys(inputJsonSchema.properties).forEach((key, index, array) => {
      if (index > 0 && index < array.length) {
        udfInput += ', ';
      }
      udfInput += `${key}: ${key}`;
    });

    return udfInput + '}';
  }

  return '';
};

const UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP: Record<ServiceUrn, string> = {
  'urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation':
    'udf(`urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation`, {approvedBudget: approvedBudget, budgetCurrency: contractCurrency})',
};

const removeVersionFromUrn = (urn: ServiceUrn) => urn.replace(/\/v\d+$/, '');

/*
 * UDFs function calls are not part of the service definition, so we need to manually map them.
 * If the UDF is not in the map, we need to generate the function call based on the input schema.
 * This is temporary until we have a better way to get the UDF function call.
 * It should be built by the user using the UI, but this is not part of this iteration.
 */
const getUdfPropertyId = (service: ServiceCatalogResource) => {
  const serviceUrn = service.urn;

  const serviceUrnWithoutVersion = removeVersionFromUrn(serviceUrn);

  if (serviceUrnWithoutVersion in UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP) {
    return UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP[serviceUrnWithoutVersion];
  }

  const inputJsonSchema = getUdfInputFromInputJsonSchema(service);

  if (!inputJsonSchema) {
    return '';
  }

  return `udf(\`${serviceUrnWithoutVersion}\`, ${inputJsonSchema})`;
};

export const getSchemaFromFunctionDefinition = (service: ServiceCatalogResource): Schema => {
  const jsonSchema = service.definition?.FunctionProvider?.Interface?.Output?.JsonSchema;

  if (!jsonSchema) {
    return { properties: [] };
  }

  const propertyId = getUdfPropertyId(service);

  if (!propertyId) {
    return { properties: [] };
  }

  const isPropertyVisible = getIsPropertyVisible({
    xType: jsonSchema['x-type'],
    propertyName: service.name,
  });

  if (!isPropertyVisible) {
    return { properties: [] };
  }

  const propertyLabel = service.name;
  const propertyType = getPropertyTypes(jsonSchema);
  const typeLabel = formatPropertyType({
    propertyType,
    format: jsonSchema.format,
    xType: jsonSchema['x-type'],
    isEnum: !!jsonSchema.enum,
  });
  const origin: PropertyOrigin = { id: service.urn, kind: 'udf', label: service.name };

  const propertyProperties = getNextPropertiesParsingStep({
    propertyType: propertyType[0],
    propertyLabel,
    propertyId,
    origin,
    jsonSchema,
  });

  if ((propertyType.includes('object') || propertyType.includes('array')) && propertyProperties.length === 0) {
    return { properties: [] };
  }

  return {
    properties: [
      {
        id: propertyId,
        label: propertyLabel,
        type: propertyType,
        xType: jsonSchema['x-type'],
        format: jsonSchema.format,
        isVisible: isPropertyVisible,
        typeLabel,
        origin,
        required: jsonSchema.required?.includes(propertyLabel) || false,
        properties: propertyProperties,
      },
    ],
  };
};

const getNextPropertiesParsingStep = ({
  propertyType,
  propertyLabel,
  propertyId,
  origin,
  jsonSchema,
}: {
  propertyType: PropertyType;
  jsonSchema: JsonSchema;
  origin: PropertyOrigin;
  propertyId: string;
  propertyLabel: string;
}): Property[] => {
  const properties =
    propertyType === 'array' && jsonSchema.items
      ? convertJsonSchemaToSchema({
          jsonSchema: jsonSchema.items,
          origin,
          parentPropertyId: propertyId,
          parentPropertyLabel: propertyLabel,
          parentPropertyType: 'array',
        }).properties
      : propertyType === 'object'
      ? convertJsonSchemaToSchema({
          jsonSchema: jsonSchema,
          origin,
          parentPropertyId: propertyId,
          parentPropertyLabel: propertyLabel,
          parentPropertyType: 'object',
        }).properties
      : [];

  return properties.filter((property) => property.isVisible).filter(removeEmptyObjectAndArrayProperties);
};
