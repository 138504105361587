import { FC, ReactNode, useState } from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Text } from '@verticeone/design-system';
import { Placeholder } from '@verticeone/design-system';
import { IconButton } from '@verticeone/design-system';

import { useFormatDate } from '@verticeone/utils/formatting';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { TaskAssignees } from './TaskAssignees';
import { useTaskContext } from '../TaskContext';
import AssigneeSelect from '../../../components/AssigneeSelect';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useAssignUserTaskMutation, UserTask } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { testProps } from '@verticeone/design-system';
import { GenericDateIcon, GenericRequestIcon, GenericUserIcon } from '../../../components/icons/Icons';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { isSaaSApprovalTask } from '../TaskDrawerForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { useAuthorizer } from '@verticeone/auth/src';
import { TaskDescription } from './TaskDescription';

type DetailRowProps = {
  icon: FC;
  label: ReactNode;
  linkTo?: string;
  testId?: string;
};

const DetailRow: FC<DetailRowProps> = ({ icon: Icon, label, linkTo, testId }) => {
  return (
    <Stack direction="row" alignItems="center" height={17} {...testProps(testId, 'detailRow')}>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
        <Icon />
        <Text variant="body-regular" size="S" color="text2">
          {label}
        </Text>
      </Stack>
      {linkTo && (
        <IconButton
          icon={OpenInNew}
          size="S"
          variant="plain"
          color="transparent"
          component={Link}
          to={linkTo}
          sx={{ marginLeft: -1 }}
        />
      )}
    </Stack>
  );
};

const canReassignTask = (task: UserTask, useCanReassignTask: boolean) =>
  useCanReassignTask && !Array<UserTask['status']>('COMPLETED', 'TERMINATED').includes(task.status);

export const TaskDrawerInfo = () => {
  const [editingAssignees, setEditingAssignees] = useState(false);
  const { t } = useTranslation();
  const { taskOverview, task } = useTaskContext();

  const formatDate = useFormatDate();
  const createdAt = formatDate(task.createdAt);

  const { accountId } = useAccountContext();
  const { isAllowed: canReassign } = useAuthorizer({
    id: `ReassignTask_${task.taskId}`,
    object: `urn:verticeone:vertice:${accountId}:workflows:task/${task.taskId}`,
    action: 'task:Assign',
  });

  const [assignUserMutation, { isLoading: isAssigningUserTask }] = useAssignUserTaskMutation();
  const handleAssignmentChange = (assignment: string[]) => {
    void assignUserMutation({
      accountId,
      taskId: task.id,
      assignUserTask: {
        assignments: assignment,
      },
    });
  };

  const taskCompletedAt = task.status === 'COMPLETED' && task.closedAt;
  const isEditingAssigneesEnabled = !taskCompletedAt && canReassign;

  return (
    <Stack gap={4} paddingX={6} {...testProps('taskDrawerContent')}>
      <Stack direction="row" width="100%" gap={4}>
        <Stack gap={4} width="50%">
          <DetailRow
            label={
              taskOverview?.task ? (
                canReassignTask(taskOverview.task, canReassign) && editingAssignees ? (
                  <AssigneeSelect
                    task={taskOverview.task}
                    onChange={handleAssignmentChange}
                    isLoading={isAssigningUserTask}
                    size="S"
                    color="secondary"
                    variant="solid"
                  />
                ) : (
                  <TaskAssignees
                    assignees={task.assignees}
                    setEdit={isEditingAssigneesEnabled ? setEditingAssignees : undefined}
                  />
                )
              ) : (
                <Placeholder width={150} />
              )
            }
            testId="assignees"
            icon={GenericUserIcon}
          />
          <RequestDetailRow taskOverview={taskOverview} />
        </Stack>
        <Stack gap={4}>
          {taskCompletedAt ? (
            <DetailRow
              label={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETED_ON', { completedAt: formatDate(taskCompletedAt) })}
              icon={GenericDateIcon}
              testId="completedOn"
            />
          ) : (
            <DetailRow
              label={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.ACTIVE_SINCE', { createdAt })}
              icon={GenericDateIcon}
              testId="activeFor"
            />
          )}
        </Stack>
      </Stack>
      <TaskDescription description={taskOverview?.task.description} />
    </Stack>
  );
};

const RequestDetailRow: FC<{ taskOverview?: TaskOverview }> = ({ taskOverview }) => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();

  if (isSaaSApprovalTask(taskOverview)) {
    const contractLink = taskOverview?.task.input?.contractId
      ? generatePathForRoute(routes.CONTRACTS.DETAIL, {
          contractId: taskOverview?.task.input?.contractId,
        })
      : undefined;

    return (
      <DetailRow
        label={taskOverview?.workflow?.instanceName || '–'}
        icon={GenericRequestIcon}
        linkTo={contractLink}
        testId="requestName"
      />
    );
  }

  const requestRef = taskOverview?.request?.ref;
  const requestLink = requestRef
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
        requestId: parseRequestRef(requestRef).requestId,
      })
    : undefined;

  return (
    <DetailRow
      label={taskOverview?.request?.name || '–'}
      icon={GenericRequestIcon}
      linkTo={requestLink}
      testId="requestName"
    />
  );
};
