import { FC, useMemo, useState } from 'react';
import { QueryBuilder as ReactQueryBuilder, formatQuery } from 'react-querybuilder';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import type { RuleGroupType } from 'react-querybuilder';

import type { Property } from '../../../types';
import type { FlowEdgeCondition } from '../../../../../definitionsTypes';
import {
  OperatorSelector,
  CombinatorSelector,
  FieldSelector,
  AddRuleAction,
  AddGroupAction,
  RemoveRuleAction,
  RuleGroup,
  Rule,
  RemoveGroupAction,
  ValueEditor,
} from './components';
import { isQueryValid } from './utils';
import { useFieldsFromVariables } from './useFieldsFromVariables';

const DEFAULT_QUERY: RuleGroupType = {
  combinator: 'and',
  rules: [
    {
      field: '',
      value: '',
      operator: '',
    },
  ],
};

type QueryBuilderCustomContext = {
  requestProperties: Property[];
  globalUDFProperties: Property[];
  isConditionRemovalEnabled: boolean;
};

type QueryBuilderProps = {
  initialCondition?: string;
  onConditionChange: (condition: string, conditionType?: FlowEdgeCondition['conditionType']) => void;
  isConditionRemovalEnabled: boolean;
  requestProperties: Property[];
  globalUDFProperties: Property[];
};

export const QueryBuilder: FC<QueryBuilderProps> = ({
  onConditionChange,
  initialCondition,
  globalUDFProperties,
  requestProperties,
  isConditionRemovalEnabled,
}) => {
  const [query, setQuery] = useState<RuleGroupType>(
    initialCondition ? parseJsonLogic(initialCondition) : DEFAULT_QUERY
  );

  const customContext: QueryBuilderCustomContext = useMemo(
    () => ({
      isConditionRemovalEnabled,
      globalUDFProperties,
      requestProperties,
    }),
    [globalUDFProperties, isConditionRemovalEnabled, requestProperties]
  );

  const fields = useFieldsFromVariables([...requestProperties, ...globalUDFProperties]);

  const handleQueryChange = (newQuery: RuleGroupType) => {
    setQuery(newQuery);

    if (!isQueryValid(newQuery)) {
      onConditionChange('');
      return;
    }

    onConditionChange(JSON.stringify(formatQuery(newQuery, 'jsonlogic')), 'JsonLogic');
  };

  return (
    <ReactQueryBuilder
      controlElements={{
        removeRuleAction: RemoveRuleAction,
        combinatorSelector: CombinatorSelector,
        fieldSelector: FieldSelector,
        rule: Rule,
        addRuleAction: AddRuleAction,
        addGroupAction: AddGroupAction,
        valueEditor: ValueEditor,
        operatorSelector: OperatorSelector,
        ruleGroup: RuleGroup,
        removeGroupAction: RemoveGroupAction,
      }}
      query={query}
      onQueryChange={handleQueryChange}
      showCombinatorsBetweenRules
      context={customContext}
      fields={fields}
    />
  );
};
