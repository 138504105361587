import { Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useOfferItemMapping } from './helpers/itemMapping';
import { OfferType } from './types';
import { Offer as OfferT } from '../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { OfferSubSection } from './OfferSubSection';
import { BaselineOfferFooter, NegotiatedOfferFooter } from './OfferFooters';
import { ReactNode } from 'react';

export type OfferProps = {
  type: OfferType;
  vendor: ReturnType<typeof useOfferItemMapping>['vendor'];
  products: ReturnType<typeof useOfferItemMapping>['products'];
  concessions: ReturnType<typeof useOfferItemMapping>['concessions'];
  offer: OfferT;
  baselineOffer?: OfferT;
} & (
  | {
      header: ReactNode;
      title?: never;
    }
  | {
      header?: never;
      title: string;
    }
);

export const Offer = ({ title, header, vendor, type, products, concessions, offer, baselineOffer }: OfferProps) => {
  const { palette } = useTheme();

  return (
    <Stack borderRadius={4} border={`1px solid ${palette.core.color3}`} bgcolor={palette.core.bg}>
      <Stack borderBottom={`1px solid ${palette.core.color3}`} py={4.25} px={6}>
        {header && header}
        {title && (
          <Text variant="heading" size="S" color="text1">
            {title}
          </Text>
        )}
      </Stack>
      <Stack borderBottom={`1px solid ${palette.core.color3}`} py={4} px={6} gap={6}>
        <OfferSubSection variant="vendor" name={vendor.name} items={vendor.items} />
        {products.map((product, index) => (
          <OfferSubSection
            variant="product"
            key={['product', product.name, index].join()}
            name={product.name}
            items={product.items}
          />
        ))}
        {concessions.map((concession, index) => (
          <OfferSubSection
            key={[concession.name, index].join()}
            variant="concession"
            name={concession.name}
            items={concession.items}
          />
        ))}
        {offer.additionalNotes && (
          <OfferSubSection
            variant="insights"
            items={[
              {
                value: offer.additionalNotes,
              },
            ]}
          />
        )}
      </Stack>
      {type === 'baseline' && <BaselineOfferFooter baselineOffer={offer} />}
      {(type === 'enumerated' || type === 'final') && baselineOffer !== undefined && (
        <NegotiatedOfferFooter offer={offer} baselineOffer={baselineOffer} />
      )}
    </Stack>
  );
};
