import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { Box, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import { useTranslation } from 'react-i18next';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { getTextVariantStyle } from '@verticeone/design-system';
import { LoadableComponent } from '@verticeone/design-system';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  cssObjectToString,
  dayInMonthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useGraphData } from './useGraphData';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useGraph } from '../../../components/providers/GraphProvider';
import GraphLayout from '../../../components/Graph/GraphLayout';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

const useGraphSeries = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.SAGEMAKER.GRAPH' });
  const { isOnDemandUsageOnly, shoppingCartUnits } = useGraph();
  const { data, isFetching } = useGraphData();

  return useMemo(
    () => ({
      hasDataFetched: !isFetching && data !== null,
      categories: data?.map((item) => item.date) ?? [],
      lastDataTimeIndex: data?.findIndex((item) => item.spPurchaseRecommendation !== null),
      lastDataTime: data?.find((item) => item.spPurchaseRecommendation !== null),
      series: [
        {
          id: 'shoppingCartUnits',
          type: 'column',
          name: t('SHOPPING_CART'),
          color: getColorPattern(palette.warning.color2),
          data: data?.map((item) => item.shoppingCartUnits) ?? [],
          opacity: 1,
          states: {
            hover: { color: getColorPattern(palette.warning.color2), opacity: 1 },
            inactive: { opacity: 0.5 },
          },
          custom: {
            legendOutlined: true,
            legendColor: getColorPattern(palette.warning.color2),
            tooltipColor: getColorPattern(palette.warning.color2),
          },
          index: 1,
          visible: !!shoppingCartUnits,
        },
        {
          id: 'onDemandUsage',
          type: 'column' as const,
          name: t('ON_DEMAND_USAGE'),
          color: 'transparent',
          data: data?.map((item) => item.onDemandUsage) ?? [],
          custom: {
            legendOutlined: true,
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color5,
          },
        },
        {
          id: 'spCoveredUsage',
          type: 'column' as const,
          name: t('SAVINGS_PLANS_COVERED_USAGE'),
          color: palette.visualization.monochromatic.primary['80'],
          data: data?.map((item) => item.spCoveredUsage) ?? [],
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.primary['50'],
            },
          },
          custom: {
            legendColor: palette.visualization.monochromatic.primary['50'],
          },
        },
        {
          id: 'averageUsage',
          type: 'line' as const,
          name: t('AVERAGE_COST'),
          color: palette.text.color1,
          data: data?.map((item) => item.averageUsage) ?? [],
          custom: {
            legendColor: palette.text.color1,
          },
          visible: !isOnDemandUsageOnly,
        },
        {
          id: 'onDemandCostPerDay',
          type: 'line' as const,
          name: t('ON_DEMAND_AVERAGE_COST'),
          color: palette.text.color1,
          data: data?.map((item) => item.onDemandCostPerDay) ?? [],
          custom: {
            legendColor: palette.text.color1,
          },
          visible: isOnDemandUsageOnly,
        },
        {
          id: 'totalUsage',
          type: 'line' as const,
          name: t('TOTAL_USAGE'),
          color: palette.text.color1,
          dashStyle: 'Dot' as const,
          data: data?.map((item) => item.totalUsage) ?? [],
          custom: {
            legendColor: getColorPattern(palette.text.color1),
          },
        },
        {
          id: 'spPurchaseRecommendation',
          type: 'line' as const,
          name: t('COVERAGE_RECOMMENDATION'),
          color: palette.primary.color2,
          data: data?.map((item) => item.spPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.primary.color2,
          },
          visible: !isOnDemandUsageOnly,
        },
        {
          id: 'spOnDemandPurchaseRecommendation',
          type: 'line' as const,
          name: t('VERTICE_RECOMMENDATION'),
          color: palette.tertiary.color2,
          data: data?.map((item) => item.spOnDemandPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.tertiary.color2,
          },
          visible: isOnDemandUsageOnly,
        },
      ],
    }),
    [data, isFetching, isOnDemandUsageOnly, palette, shoppingCartUnits, t]
  );
};

type GraphProps = Omit<ReturnType<typeof useGraphSeries>, 'hasDataFetched'>;

const Graph = ({ series, categories, lastDataTimeIndex }: GraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.SAGEMAKER.GRAPH' });
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const [chart, setChartRef] = useChartRef();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const formatDate = useFormatDate();
  const legend = series
    .filter(({ visible }) => visible !== false)
    .map(({ custom: { legendColor: color, legendOutlined: outlined }, name: label, id }) => ({
      id,
      color,
      label,
      outlined,
    }));

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { spacingTop: 12 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          xAxis: {
            categories,
            minPadding: 0,
            maxPadding: 0,
            labels: { formatter: dayInMonthFormatter },
            plotLines: [
              {
                color: palette.core.color6,
                value: lastDataTimeIndex,
                width: 1,
                zIndex: 3,
              },
            ],
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) => {
                return `<span style="${cssObjectToString(yLabelStyles(palette))}">${formatCurrency(value as number, {
                  currency: AWS_DEFAULT_CURRENCY,
                })}</span>`;
              },
              x: 50,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(getTextVariantStyle({ variant: 'button-bold', size: 'S' }) as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          tooltip: {
            shared: true,
          },
          series: series as Highcharts.SeriesOptionsType[],
        },
        opts
      ),
  ]);

  return (
    <>
      <Box marginLeft="auto" marginTop={4} marginRight={6}>
        {<Legend items={legend} />}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ x, points }) => {
          const filteredPoints = points?.filter((point) => {
            const isPurchasedRecommendation = [
              'spPurchaseRecommendation',
              'spOnDemandPurchaseRecommendation',
              'shoppingCartUnits',
            ].includes(point.series.userOptions.id!);
            return points?.length > 2 !== isPurchasedRecommendation;
          });

          return (
            <TooltipWrapper minWidth="370px">
              <TooltipValueWithTimeInfo value="" timeInfo={formatDate(x!.toString())} />
              {filteredPoints?.map((point) => {
                const custom = point.series.userOptions.custom as any;

                return (
                  <TooltipSeriesValuePair
                    seriesColor={custom.tooltipColor ?? custom.legendColor}
                    seriesName={point.series.userOptions.name}
                    value={(Math.round(point!.y! * 100) / 100).toString()}
                    key={point.series.userOptions.id}
                  />
                );
              })}
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </>
  );
};

const LoadableGraph = () => {
  const { hasDataFetched, ...otherProps } = useGraphSeries();

  return (
    <LoadableComponent isLoading={!hasDataFetched} color={AWS_BRAND_COLOR}>
      <Graph {...otherProps} />
    </LoadableComponent>
  );
};

export type GraphContainerProps = {
  shoppingCartUnits?: number;
  forceOnDemandUsageOnly?: boolean;
};

const GraphContainer = ({ shoppingCartUnits, forceOnDemandUsageOnly = false }: GraphContainerProps) => (
  <GraphLayout shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={forceOnDemandUsageOnly}>
    <LoadableGraph />
  </GraphLayout>
);

export default GraphContainer;
