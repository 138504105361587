import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { Dialog, Button, IconWrapper } from '@verticeone/design-system';
import { AccountTreeOutlined, LanguageOutlined } from '@mui/icons-material';

import type { Property, PropertyId } from '../types';
import SearchInput from '../../../../../components/SearchInput';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { VariablesList } from './VariablesList';
import { FilterOption, VariablesGroup } from './types';
import { filterVariablesGroups } from './utils';

const DialogHeader = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.neutral.color3}`,
}));

const DialogContent = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  overflowY: 'auto',
}));

const useVariablesGroupsFilterOptions = (variablesGroupsIds: string[]): FilterOption[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.FILTER',
  });

  return useMemo(() => {
    return [
      {
        id: 'all',
        label: t('ALL'),
      },
      ...[
        {
          id: 'workflow.request',
          label: t('REQUEST'),
          icon: AccountTreeOutlined,
        },
        {
          id: 'workflow.global',
          label: t('GLOBAL'),
          icon: LanguageOutlined,
        },
      ].filter((filterOption) => variablesGroupsIds.includes(filterOption.id)),
    ];
  }, [variablesGroupsIds, t]);
};

const useVariablesDialogSetup = (variablesGroups: VariablesGroup[]) => {
  const allVariablesCount = variablesGroups.reduce((count, group) => count + group.count, 0);
  const filterOptions = useVariablesGroupsFilterOptions(variablesGroups.map((group) => group.id));

  return {
    allVariablesCount,
    filterOptions,
  };
};

const useVariablesFilterState = () => {
  const [searchValue, setSearchValue] = useState('');
  const [groupFilter, setGroupFilter] = useState('all');

  return {
    searchValue,
    setSearchValue,
    groupFilter,
    setGroupFilter,
  };
};

export type VariablesDialogProps = {
  variablesGroups: VariablesGroup[];
  isOpen: boolean;
  onClose: () => void;
  onVariableClick: (property: Property) => void;
  selectedPropertyId?: PropertyId;
  positionFromTheRight?: number;
};

export const VariablesDialog: FC<VariablesDialogProps> = ({
  isOpen,
  onClose,
  variablesGroups,
  onVariableClick,
  selectedPropertyId,
  positionFromTheRight,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR' });
  const { allVariablesCount, filterOptions } = useVariablesDialogSetup(variablesGroups);

  const { searchValue, setGroupFilter, groupFilter, setSearchValue } = useVariablesFilterState();
  const filteredVariablesGroups = filterVariablesGroups({ variablesGroups, searchValue, groupId: groupFilter });

  return (
    <Dialog
      paperSx={
        positionFromTheRight
          ? {
              right: positionFromTheRight,
              position: 'absolute',
              marginRight: 4,
            }
          : undefined
      }
      size="M"
      withTransparentBackdrop
      open={isOpen}
      onClose={onClose}
    >
      <DialogHeader width="100%" gap={2} direction="column">
        <SearchInput
          sx={{ flexGrow: 1 }}
          variant="outlined"
          value={searchValue}
          onSearch={setSearchValue}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          placeholder={t('FILTER.SEARCH_PLACEHOLDER', {
            count: allVariablesCount,
          })}
          focusOnMount
        />
        <Stack direction="row" gap={1}>
          {filterOptions.map((filterOption) => (
            <Button
              key={filterOption.id}
              color={filterOption.id === groupFilter ? 'secondary' : 'neutral'}
              variant={filterOption.id === groupFilter ? 'ghost' : 'outline'}
              onClick={() => setGroupFilter(filterOption.id)}
              size="XS"
            >
              {filterOption.icon && <IconWrapper size="XS" icon={filterOption.icon} />}
              {filterOption.label}
            </Button>
          ))}
        </Stack>
      </DialogHeader>
      <DialogContent>
        <Stack gap={4}>
          {filteredVariablesGroups.map((variableGroup) => (
            <VariablesList
              highlightedLabel={searchValue}
              key={searchValue ? `${variableGroup.id}-searching` : variableGroup.id}
              title={variableGroup.title}
              originsWithProperties={variableGroup.originsWithProperties}
              onVariableClick={onVariableClick}
              selectedPropertyId={selectedPropertyId}
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
