import { NotifMessage } from '../../contexts/NotificationWebSocketContext/types';
import { REQUEST_REF_SUFFIX, TASK_REF_SUFFIX } from '../../modules/intelligentWorkflows/constants';

export const parseWorkflowVersionRef = (workflowVersionRef: string) => {
  const parts = workflowVersionRef.split('/');
  if (parts.length < 3) {
    throw new Error('Invalid workflow version ref');
  }

  return {
    workflowId: parts[parts.length - 3],
    workflowVersion: parts[parts.length - 1],
  };
};

/**
 * Safely parse workflow version ref
 * Expected format is: urn:verticeone:vertice:{accountId}:workflows:workflow/{workflowId}/version/{workflowVersion}
 * Version part will be ignored
 * @param workflowRef
 */
export const parseWorkflowRef = (workflowRef?: string) => {
  if (!workflowRef) {
    return { workflowId: undefined };
  }

  const resourcePart = workflowRef.split(':').pop();
  if (!resourcePart) {
    return { workflowId: undefined };
  }
  const resourceParts = resourcePart.split('/');
  if (resourceParts[0] !== 'workflow') {
    return { workflowId: undefined };
  }

  return {
    workflowId: resourceParts[1],
  };
};

export const parseWorkflowInstanceRef = (workflowVersionRef: string) => {
  const parts = workflowVersionRef.split('/');
  if (parts.length < 2) {
    throw new Error('Invalid workflow instance ref');
  }
  return {
    workflowInstanceId: parts[parts.length - 1],
  };
};

export const parseUserTaskRef = (taskRef: string) => {
  const parts = taskRef.split('/');
  if (parts.length !== 2) {
    throw new Error('Invalid task ref');
  }
  const colonSeparatedParts = parts[0].split(':');

  return {
    taskId: parts[parts.length - 1],
    accountId: colonSeparatedParts[0] === 'urn~3' ? colonSeparatedParts[1] : colonSeparatedParts[3],
  };
};

export const parseRequestRef = (requestRef: string) => {
  const parts = requestRef.split('/');
  if (parts.length < 2) {
    throw new Error(`Failed to parse request id from ref "${requestRef}"`);
  }
  const urnParts = parts[0].split(':');
  return {
    accountId: urnParts[0] === 'urn~3' ? urnParts[1] : urnParts[3],
    requestId: parts[1],
  };
};

export const parseRequestRefSafe = (requestRef: string) => {
  try {
    return parseRequestRef(requestRef);
  } catch {
    return { requestId: undefined };
  }
};

export const parseResourceRef = (resourceRef: string) => {
  const segments = resourceRef.split(':');
  const parts = resourceRef.split('/');
  if (parts.length < 2) {
    throw new Error(`Failed to parse resource id from ref "${resourceRef}"`);
  }
  return {
    accountId: segments[3],
    resourceId: parts[1],
  };
};

const USER_REF_PREFIX = 'urn:verticeone:vertice::iam:user';

export const isUserRef = (ref: string) => ref.startsWith(USER_REF_PREFIX);

export const formatUserRef = (userId: string) => {
  return userId.startsWith('urn:verticeone:vertice::iam:user') ? userId : `urn:verticeone:vertice::iam:user/${userId}`;
};

export const parseUserRef = (userRef: string) => {
  const parts = userRef.split('/');
  if (parts.length < 2) {
    throw new Error(`Failed to parse user id from ref "${userRef}"`);
  }
  return {
    userId: parts[1],
  };
};

type ParsedGenericHandlerRef = {
  handlerType?: string;
};
type ParsedWorkflowHandlerRef = {
  handlerType: 'vertice-workflows';
  workflowId: string;
};

type ParsedHandlerRef = ParsedGenericHandlerRef | ParsedWorkflowHandlerRef;

export const parseServiceHandlerRef = (handlerRef?: string): ParsedHandlerRef => {
  const parts = handlerRef?.split(':');
  if (parts?.length === 6) {
    const handlerType = parts[4];
    if (handlerType === 'vertice-workflows') {
      const workflowHandlerComponents = parts[5].split('/');
      if (workflowHandlerComponents.length < 2) {
        return { handlerType };
      } else {
        return {
          handlerType,
          workflowId: workflowHandlerComponents[1],
        };
      }
    }
  }
  return { handlerType: undefined };
};

export const isVerticeWorkflowHandler = (
  parsedHandler: ParsedHandlerRef
): parsedHandler is ParsedWorkflowHandlerRef => {
  return parsedHandler.handlerType === 'vertice-workflows';
};

export const extractResourceType = (ref: string) => {
  const parts = ref.split(':');
  const resourcePart = parts.pop();
  if (!resourcePart) {
    return undefined;
  }
  return resourcePart.split('/')[0];
};

const RESOURCE_REF_PREFIX = 'arn:';
export const isResourceRef = (ref: string) => ref.startsWith(RESOURCE_REF_PREFIX);
export const isWorkflowInstanceRef = (ref: string) => extractResourceType(ref) === 'workflow-instance';

export const isSaasRenewalServiceRef = (ref: string) => ref.includes('services:service/saas/renewal');
export const isSaasNewPurchaseServiceRef = (ref: string) => ref.includes('services:service/saas/purchase');

// create virtual service identifier that can be used as a unique key in pages URL
export const createServiceIdentifier = (serviceRef: string) => {
  const parts = serviceRef.split(':');
  return parts[parts.length - 1].replaceAll('/', '-');
};

export const isTaskRef = (message: NotifMessage) => message.message_attributes.ref.includes(TASK_REF_SUFFIX);
export const isRequestRef = (message: NotifMessage) => message.message_attributes.ref.includes(REQUEST_REF_SUFFIX);
export const isCurrentRequest = (requestId: string) => (message: NotifMessage) => {
  return isRequestRef(message) && message.message_attributes.ref.includes(requestId);
};

// parses request id from child request ref
// expected format is: urn:verticeone:vertice:{accountId}:requests:request/{requestId}_child
export const parseRequestIdFromChildRequestRef = (ref: string) => {
  const idPart = parseRequestRefSafe(ref).requestId;

  if (!idPart) return undefined;

  const parts = idPart.split('_');

  if (parts.length < 2) return undefined;

  return parts[0];
};
