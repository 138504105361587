import { Stack, styled, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { usePagesContext } from './PagesContext';
import { Check } from '@mui/icons-material';
import { Divider, Text } from '@verticeone/design-system';

const ProgressCircle = styled(Stack)(({ theme: { palette } }) => ({
  borderRadius: 12,
  border: `3px solid ${palette.core.color5}`,
  width: 24,
  height: 24,
  fill: palette.success.color2,
}));

const NotVisitedCircle = styled(Stack)(({ theme: { palette } }) => ({
  borderRadius: 12,
  border: `2px solid ${palette.inactive.color3}`,
  width: 24,
  height: 24,
  fill: palette.success.color2,
}));

const CheckedCircle = styled(Check)(({ theme: { palette } }) => ({
  background: palette.success.color4,
  borderRadius: 12,
  border: `2px solid ${palette.success.color4}`,
  width: 24,
  height: 24,
  fill: palette.success.color2,
}));

type PagesSidebarItemProps = {
  label: string;
  visited: boolean;
  valid: boolean;
  onClick: () => void;
};

const PagesSidebarItem: FC<PagesSidebarItemProps> = ({ label, valid, visited, onClick }) => {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      sx={{ cursor: visited ? 'pointer' : 'auto' }}
      onClick={visited ? onClick : undefined}
    >
      <Stack>{!visited ? <NotVisitedCircle /> : valid ? <CheckedCircle /> : <ProgressCircle />}</Stack>
      <Text
        variant={visited ? 'button-bold' : 'button-regular'}
        size="S"
        color={palette.text[visited ? 'color2' : 'color3']}
      >
        {label}
      </Text>
    </Stack>
  );
};

export const PagesSidebar: FC = () => {
  const { palette } = useTheme();
  const { pages, validPages, visitedPages, goToPage } = usePagesContext();

  if (pages === undefined) {
    return;
  }

  return (
    <Stack direction="row" width="350px" bgcolor={palette.background.default} zIndex={1} justifyContent="space-between">
      <Stack p={6} gap={6}>
        {pages.map((page) => {
          return (
            <PagesSidebarItem
              key={page.id}
              visited={visitedPages.has(page.id)}
              label={page.title}
              valid={validPages.has(page.id)}
              onClick={() => goToPage(page.id)}
            />
          );
        })}
      </Stack>
      <Divider orientation="vertical" />
    </Stack>
  );
};
