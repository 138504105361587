import { useTranslation } from 'react-i18next';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { IconButton, Tooltip } from '@verticeone/design-system';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { EditOutlined } from '@mui/icons-material';
import EditExecutionDateModal from './ExecutionDateModal';
import { GridCellModes } from '@mui/x-data-grid/models/gridEditRowModel';

const EditableStartDayCell = ({ id, field, value, row }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const isExecutionDateModalOpen = apiRef.current.getCellMode(id, field) === GridCellModes.Edit;
  const effectiveDate = new Date(value);
  const executeImmediately = row.executeImmediately === 1 || false; // todo not implemented yet for offering data
  const { t } = useTranslation(undefined, {
    keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.RECOMMENDED_TAB.TABLE.EFFECTIVE_DAY_CELL',
  });

  const setEditMode = (edit: boolean) => {
    if (edit) {
      void apiRef.current.startRowEditMode({ id });
    } else {
      void apiRef.current.stopRowEditMode({ id });
    }
  };

  const handleEditExecutionDate = () => {
    setEditMode(true);
  };

  const label = executeImmediately ? t('IMMEDIATELY') : dayjs(effectiveDate).format('MMM DD, YYYY');

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Text variant="body-bold">{label}</Text>
        <Tooltip size="S" content={t('CHANGE_PURCHASE_DATE')}>
          <IconButton size="M" variant="plain" onClick={handleEditExecutionDate} icon={EditOutlined} />
        </Tooltip>
      </Stack>
      <EditExecutionDateModal
        open={isExecutionDateModalOpen}
        setOpen={setEditMode}
        effectiveDate={effectiveDate}
        executeImmediately={executeImmediately}
        onChange={(data) => {
          void apiRef.current.setEditCellValue({ id, field, value: data.effectiveDate });
          void apiRef.current.setEditCellValue({
            id,
            field: 'executeImmediately',
            value: data.executeImmediately ? 1 : 0,
          });

          setEditMode(false);
        }}
      />
    </>
  );
};

export default EditableStartDayCell;
