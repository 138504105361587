import { FC, useState } from 'react';
import { Stack, useTheme, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@verticeone/design-system';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import { GatewayDefinition, FlowEdgeDefinition, ProcessDefinition, Gateway } from '../../definitionsTypes';
import { EditorDrawer } from './EditorDrawer';
import { EditGatewayForm } from './forms/EditGatewayForm/EditGatewayForm';
import { EditGatewayFormData, editGatewayFormSchema } from './forms/EditGatewayForm/schema';
import { AvailableVariablesDialog } from './AvailableVariablesDialog';
import { isFlowEdgeConditionConfiguration } from '../../pocWorkflowSchema';
import { updateEdgeDefinition } from '../../definitions/edgeDefinition';

export const DRAWER_WIDTH = 564;

const putDefaultEdgeLast = (edges: FlowEdgeDefinition[]) => {
  const defaultEdge = edges.find(
    ({ edge }) => edge.configurations?.find(isFlowEdgeConditionConfiguration)?.condition.conditionType === 'DefaultFlow'
  );

  if (!defaultEdge) {
    return edges;
  }

  return edges.filter(({ edge }) => edge.id !== defaultEdge.edge.id).concat(defaultEdge);
};

const getEdgesDefaultValues = ({
  edges,
  gatewayType,
}: {
  edges: FlowEdgeDefinition[];
  gatewayType: Gateway['gatewayType'];
}) => {
  const skipDefaultEdgeSort = gatewayType === 'Fork';

  return (skipDefaultEdgeSort ? edges : putDefaultEdgeLast(edges)).map(({ edge }) => {
    const edgeCondition = edge.configurations?.find(isFlowEdgeConditionConfiguration);

    return {
      id: edge.id,
      name: edge.name || '',
      condition: edgeCondition?.condition.expression?.replace(/\s+/g, ' ') || '',
      conditionType: edgeCondition?.condition.conditionType || 'JsonLogic',
    };
  });
};

const getFinalEdgeCondition = (edge: EditGatewayFormData['edges'][number]) => {
  if (edge.condition === '') {
    return {
      conditionType: 'DefaultFlow',
    };
  }

  return {
    conditionType: edge.conditionType,
    expression: edge.condition,
  };
};

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
});

const DrawerHeader = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  paddingRight: theme.spacing(16),
  borderBottom: `1px solid ${theme.palette.core.color3}`,
}));

type EditGatewayDrawerProps = {
  isOpen: boolean;
  gatewayDefinition?: GatewayDefinition;
  gatewayLeavingEdges: FlowEdgeDefinition[];
  onClose: VoidFunction;
  onSave: (args: { gateway: GatewayDefinition; edges: FlowEdgeDefinition[] }) => void;
  onDirty: VoidFunction;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
};

const GATEWAY_DESCRIPTION_KEY_MAP = {
  Exclusive: 'EDIT_GATEWAY.GATEWAY_TYPE.EXCLUSIVE.DESCRIPTION',
  Fork: 'EDIT_GATEWAY.GATEWAY_TYPE.FORK.DESCRIPTION',
  Join: 'EDIT_GATEWAY.GATEWAY_TYPE.JOIN.DESCRIPTION',
} as const;

const EditGatewayDrawerContent: FC<Omit<Required<EditGatewayDrawerProps>, 'isOpen'>> = ({
  gatewayDefinition,
  onClose,
  onSave,
  onDirty,
  processDefinition,
  gatewayLeavingEdges,
  workflowServiceRef,
}) => {
  const { gateway } = gatewayDefinition;
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });
  const { palette } = useTheme();

  const [isGatewayVariablesDialogOpen, setIsGatewayVariablesDialogOpen] = useState(false);

  const handlers = useForm<EditGatewayFormData>({
    resolver: zodResolver(editGatewayFormSchema),
    defaultValues: {
      gatewayType: gateway.gatewayType,
      edges: getEdgesDefaultValues({ edges: gatewayLeavingEdges, gatewayType: gateway.gatewayType }),
    },
  });

  const onSubmit = (data: EditGatewayFormData) => {
    const updatedEdgeDefinitions: FlowEdgeDefinition[] = [];

    for (const edge of data.edges) {
      const outgoingLeavingEdge = gatewayLeavingEdges.find((leavingEdge) => leavingEdge.edge.id === edge.id);

      if (!outgoingLeavingEdge) {
        continue;
      }

      updatedEdgeDefinitions.push(
        updateEdgeDefinition({
          edgeDefinition: outgoingLeavingEdge,
          edge: {
            name: edge.name,
            configurations: [
              {
                kind: 'ProcessEngine:FlowEdgeCondition',
                condition: getFinalEdgeCondition(edge),
                version: outgoingLeavingEdge.edge.configurations?.find(isFlowEdgeConditionConfiguration)?.version,
              },
            ],
          },
        })
      );
    }

    onSave({
      gateway: {
        ...gatewayDefinition,
        gateway: {
          ...gatewayDefinition.gateway,
          gatewayType: data.gatewayType,
        },
      },
      edges: updatedEdgeDefinitions,
    });
    onClose();
  };

  return (
    <>
      <FormProvider {...handlers}>
        <StyledForm onSubmit={handlers.handleSubmit(onSubmit)}>
          <DrawerHeader>
            <Text variant="heading" size="M" color="text1">
              {t('EDIT_GATEWAY.TITLE')}
            </Text>
            <Text variant="caption" size="XS" color="text3">
              {t(GATEWAY_DESCRIPTION_KEY_MAP[gateway.gatewayType])}
            </Text>
          </DrawerHeader>
          <Stack direction="column" flexGrow={1} width="100%" minHeight={0}>
            <Stack flexGrow={1} width="100%" minHeight={0}>
              <Stack
                direction="column"
                p={6}
                justifyContent="space-between"
                overflow="auto"
                height="100%"
                maxHeight="100%"
                width="100%"
                gap={2}
              >
                <EditGatewayForm
                  processDefinition={processDefinition}
                  workflowServiceRef={workflowServiceRef}
                  gatewayId={gateway.id}
                  gatewayType={gateway.gatewayType}
                  onDirty={onDirty}
                />
                <Button
                  onClick={() => setIsGatewayVariablesDialogOpen(true)}
                  variant="outline"
                  color="secondary"
                  size="S"
                  fullWidth
                >
                  {t('EDIT_GATEWAY.DIALOG.AVAILABLE_VARIABLES')}
                </Button>
              </Stack>
            </Stack>
            <Stack
              px={6}
              py={8}
              direction="row"
              justifyContent="space-between"
              borderTop={`1px solid ${palette.core.color3}`}
            >
              <Button onClick={onClose} variant="plain" color="neutral" size="S">
                {t('ACTIONS.DISCARD_CHANGES')}
              </Button>
              <Button variant="solid" color="secondary" size="S" type="submit">
                {t('ACTIONS.APPLY')}
              </Button>
            </Stack>
          </Stack>
        </StyledForm>
      </FormProvider>
      {isGatewayVariablesDialogOpen && (
        <AvailableVariablesDialog
          processDefinition={processDefinition}
          isOpened={isGatewayVariablesDialogOpen}
          onClose={() => setIsGatewayVariablesDialogOpen(false)}
          nodeId={gateway.id}
          workflowServiceRef={workflowServiceRef}
        />
      )}
    </>
  );
};

export const EditGatewayDrawer: FC<EditGatewayDrawerProps> = ({
  isOpen,
  gatewayDefinition,
  onClose,
  onSave,
  onDirty,
  processDefinition,
  gatewayLeavingEdges,
  workflowServiceRef,
}) => {
  return (
    <EditorDrawer width={DRAWER_WIDTH} open={isOpen}>
      {gatewayDefinition && processDefinition && workflowServiceRef && (
        <EditGatewayDrawerContent
          workflowServiceRef={workflowServiceRef}
          gatewayLeavingEdges={gatewayLeavingEdges}
          onDirty={onDirty}
          onSave={onSave}
          onClose={onClose}
          gatewayDefinition={gatewayDefinition}
          key={gatewayDefinition.gateway.id}
          processDefinition={processDefinition}
        />
      )}
    </EditorDrawer>
  );
};
