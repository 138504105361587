import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { Rule as ReactQueryBuilderRule, type RuleProps } from 'react-querybuilder';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';

const RuleContainer = styled(Stack)({
  '.rule': {
    flexGrow: 1,
    padding: 0,
    display: 'grid !important',
    gridTemplateColumns: '160px 1fr 1fr auto',
  },
});

export const Rule: FC<RuleProps> = (props) => {
  const { t } = useTranslation();

  return (
    <RuleContainer direction="row" gap={2} alignItems="center">
      <Text variant="caption" size="S" color="text2">
        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.LABELS.IF')}
      </Text>
      <ReactQueryBuilderRule {...props} />
    </RuleContainer>
  );
};
