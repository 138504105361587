import { FC } from 'react';
import { TextField, DatePicker, ToggleSwitch, Select } from '@verticeone/design-system';
import type { FullOption, ValueEditorProps } from 'react-querybuilder';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';

export const ValueEditor: FC<ValueEditorProps> = ({ value, handleOnChange, type, inputType, values: options }) => {
  if (type === 'select') {
    const selectedOptions = options?.find((option) => option.value === value);
    return (
      <Select<FullOption<string>, false>
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        value={selectedOptions}
        onChange={(newValue) => handleOnChange(newValue?.value)}
        options={options}
      />
    );
  }

  if (inputType === 'date') {
    return (
      <DatePicker
        size="S"
        value={value}
        onChange={(date) => handleOnChange(date)}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      />
    );
  }

  if (inputType === 'checkbox') {
    return (
      <ToggleSwitch
        size="S"
        checked={value}
        onChange={(event) => handleOnChange(event.target.checked)}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        withCheckIcon
      />
    );
  }

  return (
    <TextField
      variant="outlined"
      size="S"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      value={value}
      onChange={(event) => handleOnChange(event.target.value)}
      type={inputType ?? 'text'}
    />
  );
};
