import { Stack } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';

import { Text } from '@verticeone/design-system';

import { getTaskServiceConfiguration } from '../../../definitions/taskDefinition';
import { ProcessDefinition, TaskDefinition, TasksThumbnail } from '../../../definitionsTypes';
import { TaskIcon } from '../../../sharedVisualStyle/taskStyle';
import { resolveTaskNodeThumbnailConfiguration } from '../../model/resolveTaskNodeThumbnailConfiguration';

import { useGetServiceCatalogResources } from '../../../hooks/useGetServiceCatalogResources';
import { EditorDrawer } from '../EditorDrawer';
import { JiraCreate } from './JiraCreate/JiraCreate';
import { EditServiceTaskBase } from './types';
import { getLastPartDiversioned } from '../../../utils';
import { JiraSync } from './JiraSync/JiraSync';

const EDIT_JIRA_DRAWER_WIDTH = 752;

const EditServiceTaskComponentMapping: Record<string, FunctionComponent<EditServiceTaskBase>> = {
  'form/core/jira/createTicket': JiraCreate,
  'form/core/jira/syncTicket': JiraSync,
};

export type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

const EditServiceTaskDrawerHeader = ({ text, thumbnail }: { text: string; thumbnail: TasksThumbnail }) => {
  if (!thumbnail) return null;

  return (
    <Stack direction="row" gap={2} alignItems={'center'} p={6}>
      <TaskIcon userId={''} status={'PENDING'} type={thumbnail.type} id={thumbnail.id} />
      <Text variant="heading" size="S" color="text1">
        {text}
      </Text>
    </Stack>
  );
};

export type EditServiceTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  close: VoidFunction;
  saveTask: (task: TaskDefinition) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
};

export const EditServiceTaskDrawer = ({
  isOpen,
  task,
  processDefinition,
  ...restProps
}: EditServiceTaskDrawerProps) => {
  const { resources } = useGetServiceCatalogResources({});

  const EditServiceTaskComponent = useMemo(() => {
    if (task) {
      const taskServiceConfiguration = getTaskServiceConfiguration(task);
      const correspondingTaskService = resources.find(
        (service) => service.urn === taskServiceConfiguration?.resourceUrn
      );

      const componentKey = getLastPartDiversioned(
        correspondingTaskService?.definition.ServiceProvider.Interface.Input.JsonSchema['x-type']
      );
      return componentKey ? EditServiceTaskComponentMapping[componentKey] : null;
    }
  }, [resources, task]);

  const thumbnail = resolveTaskNodeThumbnailConfiguration(task?.task.configurations!);

  return (
    <EditorDrawer open={isOpen} width={EDIT_JIRA_DRAWER_WIDTH}>
      <EditServiceTaskDrawerHeader text={task?.task.name!} thumbnail={thumbnail} />

      {EditServiceTaskComponent && task && processDefinition && (
        <EditServiceTaskComponent
          task={task}
          resources={resources}
          processDefinition={processDefinition}
          {...restProps}
        />
      )}
    </EditorDrawer>
  );
};
