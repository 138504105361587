import { FC, useMemo, useState } from 'react';
import { VariableChip } from '../../../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../../../VariableSelector/VariablesDialog';
import type { Property } from '../../../../types';
import { useJiraVariablesGroups } from './useJiraVariableGroups';
import { JiraIssueField } from '../../types';
import {
  transformJiraToWorkflowLikeVariable,
  transformJiraToWorkflowLikeVariables,
  transformWorkflowLikeToJiraVariable,
} from '../../utils';

type JiraVariableSelectorProps = {
  variables: JiraIssueField[];
  selectedVariable?: JiraIssueField;
  onSelectedVariableChange: (variable?: JiraIssueField) => void;
  isVariableTypeDisplayed?: boolean;
};

export const JiraVariableSelector: FC<JiraVariableSelectorProps> = ({
  variables,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const selectedWorkflowLikeVariable = useMemo(
    () => transformJiraToWorkflowLikeVariable(selectedVariable),
    [selectedVariable]
  );

  const workflowLikeVariables = transformJiraToWorkflowLikeVariables(variables);

  const variablesGroups = useJiraVariablesGroups({ variables: workflowLikeVariables });

  const handleVariableClick = (variable: Property) => {
    onSelectedVariableChange(transformWorkflowLikeToJiraVariable(variable));
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedWorkflowLikeVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedWorkflowLikeVariable?.typeLabel?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
      />
      <VariablesDialog
        variablesGroups={variablesGroups}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
      />
    </>
  );
};
