import { FC } from 'react';
import { IconButton } from '@verticeone/design-system';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import type { ActionProps } from 'react-querybuilder';

export const AddRuleAction: FC<ActionProps> = ({ handleOnClick }) => {
  return <IconButton icon={AddOutlined} size="S" variant="outline" color="neutral" onClick={handleOnClick} />;
};

export const AddGroupAction = () => null;

/*
 * Use this to test the builder for the nested conditions
    const AddGroupAction: FC<ActionProps> = ({ handleOnClick }) => {
      return (
        <IconButton
          icon={SubdirectoryArrowRightOutlined}
          size="S"
          variant="outline"
          color="neutral"
          onClick={handleOnClick}
        />
      );
    };
*/

export const RemoveGroupAction: FC<ActionProps> = ({ handleOnClick }) => {
  return <IconButton icon={DeleteOutlined} size="S" variant="outline" color="neutral" onClick={handleOnClick} />;
};

export const RemoveRuleAction: FC<ActionProps> = (props) => {
  const { handleOnClick, schema, context } = props;

  const isRemoveButtonHidden = !context.isConditionRemovalEnabled && schema.getQuery()?.rules?.length === 1;

  if (isRemoveButtonHidden) {
    return null;
  }

  return <IconButton icon={DeleteOutlined} size="S" variant="plain" color="neutral" onClick={handleOnClick} />;
};
