import { isRuleGroupType, isRuleGroupTypeIC, type RuleGroupTypeAny } from 'react-querybuilder';

export const isQueryValid = (query: RuleGroupTypeAny): boolean => {
  const { rules } = query;

  if (!rules.length) {
    return false;
  }

  return rules.every((rule) => {
    if (isRuleGroupType(rule)) {
      return isQueryValid(rule);
    }

    if (isRuleGroupTypeIC(rule)) {
      return isQueryValid(rule);
    }

    if (typeof rule === 'string') {
      return rule !== '';
    }

    return rule.field !== '' && rule.value !== '' && rule.operator !== '';
  });
};
