import { FC, useState } from 'react';

import type { Property } from '../../types';
import { useWorkflowVariablesGroups } from './useWorkflowVariablesGroups';
import { VariableChip } from '../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../VariableSelector/VariablesDialog';

type VariableSelectorProps = {
  globalUDFProperties: Property[];
  allRequestProperties: Property[];
  isDisabled?: boolean;
  selectedVariable?: Property;
  onSelectedVariableChange: (property: Property) => void;
  isVariableTypeDisplayed?: boolean;
  positionFromTheRight?: number;
};

export const WorkflowVariableSelector: FC<VariableSelectorProps> = ({
  globalUDFProperties,
  allRequestProperties,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed = true,
  positionFromTheRight,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const variablesGroups = useWorkflowVariablesGroups({
    globalUDFProperties,
    allRequestProperties,
  });

  const handleVariableClick = (variable: Property) => {
    onSelectedVariableChange(variable);
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedVariable?.typeLabel?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
      />
      <VariablesDialog
        selectedPropertyId={selectedVariable?.id}
        variablesGroups={variablesGroups}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        positionFromTheRight={positionFromTheRight}
      />
    </>
  );
};
