import React, { FC, useCallback, useEffect } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';

import { Button } from '@verticeone/design-system';
import { Alert, AlertButton } from '@verticeone/design-system';

import { COMMON_BUTTON_PROPS, INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { FormSchemaProvider } from '@vertice/core/src/modules/forms/schema/FormSchemaContext';
import { useTaskContext } from '../TaskContext';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useDraftUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { enqueueSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import { usePredefinedFormDef } from './usePredefinedFormDef';
import { TaskFormContextProvider, useTaskFormContext } from './predefinedForms/shared/TaskFormContext';
import { Divider } from '@verticeone/design-system';
import { useScrollBoundaries } from '../../../../../hooks/useScrollBoundaries';
import { Tooltip } from '@verticeone/design-system';
import { PagesSidebar } from './pages/PagesSidebar';
import { PagesProvider, usePagesContext } from './pages/PagesContext';
import { useFeatures } from '../../../../features/useFeatures';
import { FEATURES } from '../../../../features/constants';

type TaskDrawerFormProps = {
  onSubmit: (resultBody: object) => Promise<void>;
  onCancel: () => void;
  formConfig: { [p: string]: any };
};

const TaskDrawerForm: FC<TaskDrawerFormProps> = ({ onSubmit, onCancel, formConfig }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const formDef = usePredefinedFormDef(formConfig);

  const taskContextData = useTaskContext();
  const { accountId } = useAccountContext();

  const { getFeature } = useFeatures();
  const newTaskFormStructure = getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.newTaskFormStructure;
  // ignore form pages when the dedicated feature flag property is not enabled
  const formPages = newTaskFormStructure ? formDef?.pages : undefined;

  const { scrollBoundaries, onScrollHandler } = useScrollBoundaries();

  // We need to pass an empty error map to zodResolver, because we don't want to show any error messages
  const schemaOptions = { errorMap: () => ({ message: '' }) };

  const getTaskDataValue = useCallback(
    (path: string, defaultValue: any) =>
      get(taskContextData.taskOverview?.task?.draft, path) ??
      get(taskContextData.taskOverview?.task?.input, path) ??
      defaultValue,
    [taskContextData.taskOverview?.task?.draft, taskContextData.taskOverview?.task?.input]
  );

  const formMethods = useForm({
    mode: 'all', // Validate on change, blur, submit
    resolver: formDef?.schema ? zodResolver(formDef?.schema, schemaOptions) : undefined,
    defaultValues: formDef?.getDefaultValues?.(getTaskDataValue, taskContextData),
  });
  const { handleSubmit } = useGoodFormUtils(formMethods);

  const submitTransformed = async (formData: object) => {
    await onSubmit(formDef?.transformOutput ? formDef.transformOutput(formData) : formData);
  };

  const watch = formMethods.watch;
  const [updateDraftUserTask] = useDraftUserTaskMutation();

  const saveDraftDebounced = useDebouncedCallback(
    (formValues) => {
      if (formMethods.formState.isSubmitting || formMethods.formState.isSubmitted) return;
      updateDraftUserTask({
        accountId: accountId,
        taskId: taskContextData.task.id,
        body: {
          ...formValues,
        },
      }).catch((e) => {
        enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.ERROR'), {
          variant: 'error',
        });
      });
    },
    500,
    { maxWait: 1000 }
  );

  useEffect(() => {
    if (!formDef?.draftEnabled) return;
    const { unsubscribe } = watch((formValues) => {
      saveDraftDebounced(formValues);
    });
    return () => unsubscribe();
  }, [watch, formDef?.draftEnabled, saveDraftDebounced]);

  return (
    <FormSchemaProvider value={formDef?.schema ?? null}>
      <TaskFormContextProvider>
        <FormProvider {...formMethods}>
          <PagesProvider pages={formPages} schema={formDef?.schema}>
            <form
              onSubmit={handleSubmit(submitTransformed)}
              style={{ display: 'flex', minHeight: 0, height: '100%', minWidth: 0 }}
            >
              <PagesSidebar />
              <Stack minHeight={0} width="100%" height="100%">
                <Stack padding={6} overflow="auto" minHeight={0} height="100%" onScroll={onScrollHandler}>
                  {formDef ? (
                    <formDef.component />
                  ) : (
                    <Alert
                      size="S"
                      variant="ghost"
                      color="error"
                      title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.UNKNOWN_FORM')}
                      subtitle={formConfig.formUrn}
                    >
                      <AlertButton onClick={onCancel}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
                    </Alert>
                  )}
                </Stack>
                <Stack
                  sx={{
                    boxShadow: scrollBoundaries.bottom
                      ? `${palette.global.getShadow({
                          color: 'core',
                          type: 'soft',
                          depth: '3z',
                          distance: '80',
                        })}, ${palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '70' })}`
                      : 'none',
                    transition: 'box-shadow 0.3s ease-in-out',
                  }}
                >
                  <Divider />
                  {Boolean(formDef) && <DrawerFormButtons />}
                </Stack>
              </Stack>
            </form>
          </PagesProvider>
        </FormProvider>
      </TaskFormContextProvider>
    </FormSchemaProvider>
  );
};

const DrawerFormButtons = () => {
  const { t } = useTranslation();
  const formState = useFormState();
  const { hasPages, isOnLastPage, goToNextPage, goToPreviousPage, hasPreviousPage } = usePagesContext();
  const { isProcessing } = useTaskFormContext();
  const { disabled, isLoading } = getPropsForSubmitButton(formState, { disableWhenFormUntouched: false });

  // buttons for paginated form
  if (hasPages) {
    return (
      <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
        <Stack>
          {hasPreviousPage && (
            <Button type="button" variant="plain" size="S" onClick={() => goToPreviousPage()}>
              {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
            </Button>
          )}
        </Stack>

        {isOnLastPage ? (
          <Tooltip
            title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TOOLTIP')}
            size="S"
            disableHoverListener={!disabled || isProcessing || isLoading}
          >
            <Stack>
              <Button
                type="submit"
                variant="solid"
                size="S"
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                disabled={disabled || isProcessing}
                isLoading={isLoading || isProcessing}
              >
                {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TASK')}
              </Button>
            </Stack>
          </Tooltip>
        ) : (
          <Button
            type="button"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            onClick={() => goToNextPage()}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        )}
      </Stack>
    );
  }

  // button for non-paginated form
  return (
    <Stack direction="row" padding={6} gap={2}>
      <Tooltip
        title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TOOLTIP')}
        size="S"
        disableHoverListener={!disabled || isProcessing || isLoading}
      >
        <Stack width="100%">
          <Button
            type="submit"
            variant="solid"
            {...COMMON_BUTTON_PROPS}
            disabled={disabled || isProcessing}
            isLoading={isLoading || isProcessing}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.SUBMIT_AND_COMPLETE')}
          </Button>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default TaskDrawerForm;
