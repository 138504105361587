import React, { createContext, useContext, useMemo } from 'react';
import { type GridApiPro, useGridApiRef } from '@mui/x-data-grid-pro';
type TableContextProps = {
  children: React.ReactNode;
};

type TableContextType = {
  apiRef: React.MutableRefObject<GridApiPro>;
};

const TableContext = createContext<TableContextType>({} as TableContextType);

const TableContextProvider = ({ children }: TableContextProps) => {
  const apiRef = useGridApiRef();

  const value: TableContextType = useMemo(
    () => ({
      apiRef,
    }),
    [apiRef]
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export const useTableContext = () => useContext(TableContext);

export default TableContextProvider;
