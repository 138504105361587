import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system';
import { useConversations } from '../../useConversations';
import { CommentsCard } from '@verticeone/design-system';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { useLoggedUser } from '@verticeone/auth/src';
import { ConversationTab, ConversationTabs, UnreadMessageChip } from './ConversationTabs';
import { CommentPanel } from './CommentPanel';

type ConversationProps = {
  accountId: string;
  requestId: string;
  requestOwner?: string;
  parentRequest?: {
    accountId: string;
    requestId: string;
    requestOwner?: string;
  };
};

export const ConversationSection: FC<ConversationProps> = ({ accountId, requestId, requestOwner, parentRequest }) => {
  const { userId, isIatUser, roles } = useLoggedUser();
  const { usersById, isLoadingUsers } = useConversationUsers();

  const [tab, setTab] = useState<ConversationTab>('customer');
  const current = useConversations(requestId, accountId);

  const { t } = useTranslation();
  const canEditComents = isIatUser || !!roles?.includes('admin');

  return (
    <UsersContextProvider>
      <Accordion size="XS" variant="label" defaultExpanded>
        <AccordionSummary
          title={
            <Stack gap={2} direction="row" alignItems="center">
              {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.COMMENTS')}
              {!parentRequest && <UnreadMessageChip {...current} showNew />}
            </Stack>
          }
        />
        <AccordionDetails>
          <CommentsCard>
            <Stack height={800}>
              {parentRequest && (
                <ConversationTabs
                  value={tab}
                  onChange={setTab}
                  current={{ requestId, accountId }}
                  parrent={parentRequest}
                />
              )}
              {(tab === 'internal' || !parentRequest) && (
                <CommentPanel
                  userId={userId}
                  canEditComents={canEditComents}
                  usersById={usersById}
                  isLoading={isLoadingUsers}
                  accountId={accountId}
                  requestId={requestId}
                  requestOwner={requestOwner}
                />
              )}
              {tab === 'customer' && parentRequest && (
                <CommentPanel
                  userId={userId}
                  canEditComents={canEditComents}
                  usersById={usersById}
                  isLoading={isLoadingUsers}
                  {...parentRequest}
                />
              )}
            </Stack>
          </CommentsCard>
        </AccordionDetails>
      </Accordion>
    </UsersContextProvider>
  );
};
