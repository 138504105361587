import { useCallback, useMemo, useState } from 'react';
import { PredefinedFormPagesDef } from '../predefinedForms/shared/types';

export const usePagesNavigation = (pages?: PredefinedFormPagesDef) => {
  const pageIdsInOrder = useMemo(() => pages?.map((page) => page.id) ?? [], [pages]);

  const [currentPageId, setCurrentPageId] = useState<string>(pages?.[0]?.id ?? '');
  const [visitedPages, setVisitedPages] = useState<Set<string>>(new Set([currentPageId]));

  const goToPage = useCallback(
    (pageId: string) => {
      setCurrentPageId(pageId);
      setVisitedPages(new Set(visitedPages).add(pageId));
    },
    [visitedPages]
  );

  const isOnLastPage = pageIdsInOrder[pageIdsInOrder.length - 1] === currentPageId;

  const currentPageIndex = pageIdsInOrder.indexOf(currentPageId);

  const goToNextPage = useCallback(() => {
    if (currentPageIndex < pageIdsInOrder.length - 1) {
      const nextPageId = pageIdsInOrder[currentPageIndex + 1];
      setCurrentPageId(nextPageId);
      setVisitedPages(new Set(visitedPages).add(nextPageId));
    }
  }, [currentPageIndex, pageIdsInOrder, visitedPages]);

  const hasPreviousPage = currentPageIndex > 0;

  const goToPreviousPage = useCallback(() => {
    setCurrentPageId(pageIdsInOrder[currentPageIndex - 1]);
  }, [currentPageIndex, pageIdsInOrder]);

  return useMemo(
    () => ({
      currentPageId,
      visitedPages,
      goToNextPage,
      goToPreviousPage,
      hasPreviousPage,
      goToPage,
      isOnLastPage,
    }),
    [currentPageId, visitedPages, goToNextPage, goToPreviousPage, hasPreviousPage, goToPage, isOnLastPage]
  );
};
