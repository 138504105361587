import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import type { FieldSelectorProps } from 'react-querybuilder';

import type { Property } from '../../../../types';
import { findPropertyById } from '../../../../VariableSelector/utils';
import { WorkflowVariableSelector } from '../../WorkflowVariableSelector';
import { DRAWER_WIDTH } from '../../../../EditGatewayDrawer';

export const FieldSelector: FC<FieldSelectorProps> = ({ handleOnChange, context, value }) => {
  const { requestProperties, globalUDFProperties } = context;

  const [selectedVariable, setSelectedVariable] = useState<Property | undefined>(() => {
    if (value) {
      return findPropertyById([...requestProperties, ...globalUDFProperties], value) ?? undefined;
    }
  });

  const handleVariableChange = (property: Property) => {
    setSelectedVariable(property);
    handleOnChange(property.id);
  };

  return (
    <Stack sx={{ zIndex: 0 }}>
      <WorkflowVariableSelector
        isVariableTypeDisplayed={false}
        globalUDFProperties={globalUDFProperties}
        allRequestProperties={requestProperties}
        onSelectedVariableChange={handleVariableChange}
        selectedVariable={selectedVariable}
        positionFromTheRight={DRAWER_WIDTH}
      />
    </Stack>
  );
};
