import GraphHeader from './GraphHeader';
import { PropsWithChildren } from 'react';
import { GraphProvider } from '../providers/GraphProvider';
import { Stack, useTheme } from '@mui/material';
import { Card } from '@verticeone/design-system';

type GraphLayoutProps = PropsWithChildren & {
  height?: number;
  shoppingCartUnits?: number;
  forceOnDemandUsageOnly?: boolean;
};

const GraphLayout = ({
  height = 492,
  shoppingCartUnits,
  forceOnDemandUsageOnly = false,
  children,
}: GraphLayoutProps) => {
  const { palette } = useTheme();

  return (
    <GraphProvider shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={forceOnDemandUsageOnly}>
      <Card>
        <Stack bgcolor={palette.core.color1} height={height}>
          <GraphHeader />
          {children}
        </Stack>
      </Card>
    </GraphProvider>
  );
};

export default GraphLayout;
