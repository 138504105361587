import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridHeaderCell } from '@verticeone/design-system';
import React from 'react';
import { OfferingData } from '../../../../types';
import { useTermFormatter, useFormatCost } from '../../utils';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { CostBreakdownTooltip, EditableStartDayCell } from '../components/columns';

export const useColumns = (tKey: string): Array<GridColDef<OfferingData>> => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const formatTerm = useTermFormatter();
  const formatCost = useFormatCost();

  return [
    {
      field: 'planType',
      headerName: t('COLUMNS.RESOURCE'),
      flex: 1,
    },
    {
      field: 'term',
      headerName: t('COLUMNS.TERM'),
      flex: 1,
      renderCell: ({ value }) => formatTerm(value),
    },
    {
      field: 'paymentOption',
      headerName: t('COLUMNS.PAYMENT_OPTION'),
      flex: 1,
    },
    {
      field: 'hourlyCommitment',
      headerName: t('COLUMNS.HOURLY_COMMITMENT.LABEL'),
      renderHeader: (params) => (
        <GridHeaderCell
          {...params}
          tooltip={{
            title: t('COLUMNS.HOURLY_COMMITMENT.TOOLTIP'),
          }}
        />
      ),
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value),
      flex: 1,
    },
    {
      field: 'totalCost',
      headerName: t('COLUMNS.TOTAL_COST'),
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) => (
        <Stack direction="row" alignItems="center" gap={2}>
          <Text variant="body-regular" size="M">
            {formatCost(row.currencyCode, value)}
          </Text>
          <CostBreakdownTooltip
            title={t('ITEM_TOOLTIP.TOTAL_COST.TITLE')}
            items={[
              {
                label: t('ITEM_TOOLTIP.TOTAL_COST.UPFRONT_PAYMENT'),
                value: formatCost(row.currencyCode, row.upfrontCost),
              },
              {
                label: t('ITEM_TOOLTIP.TOTAL_COST.MONTHLY_PAYMENT'),
                value: formatCost(row.currencyCode, row.monthlyCost),
              },
              {
                label: t('ITEM_TOOLTIP.TOTAL_COST.TOTAL_COST'),
                value: formatCost(row.currencyCode, row.totalCost),
              },
            ]}
          />
        </Stack>
      ),
    },
    {
      field: 'expectedSavings',
      headerName: t('COLUMNS.ESTIMATED_SAVINGS'),
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value),
    },
    {
      field: 'startDate',
      headerName: t('COLUMNS.START_DATE'),
      editable: true,
      renderCell: EditableStartDayCell,
      renderEditCell: EditableStartDayCell,
      flex: 1,
    },
    {
      field: 'executeImmediately',
      editable: true,
    },
  ];
};
