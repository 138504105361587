import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { formatCurrency } from '@verticeone/utils/formatting';
import { ShoppingCartItemType } from '../providers/ShoppingCartProvider';
import { OfferingsData } from '../providers/OfferingsProvider';

export const useFormatNumber = () => {
  const { locale } = useLocaleContext();

  return (value: number) =>
    new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
    }).format(value);
};

export const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

export const calculateShoppingCartUnits = (cartItems: ShoppingCartItemType[], hourlyRecommendation: number) => {
  return cartItems.reduce((total, cartItem) => total + 24 * hourlyRecommendation, 0);
};

export const calculateRecommendedShoppingCartUnits = (
  recommendedOffering: OfferingsData | undefined,
  hourlyRecommendation: number
) => {
  const recommendedShoppingCartItem = recommendedOffering && {
    id: recommendedOffering.offeringId,
  };

  return recommendedShoppingCartItem && calculateShoppingCartUnits([recommendedShoppingCartItem], hourlyRecommendation);
};

export const useTermFormatter = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.UNITS' });

  return (value: number) => {
    const isYear = !(value % 6);
    if (isYear) {
      return t('YEAR', { count: value / 12 });
    }
    return t('MONTH', { count: value });
  };
};

export const useFormatCost = () => {
  const { locale } = useLocaleContext();

  return (currencyCode: string, cost: number) =>
    formatCurrency(cost, {
      currency: currencyCode,
      locale,
      maximumFractionDigits: 2,
    });
};
