import React from 'react';
import { Stack, type StackProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WarningAmberOutlined } from '@mui/icons-material';

import { Alert } from '@verticeone/design-system';
import { useHasMissingLookup } from './useHasMissingLookup';

export const MissingSPBanner = (stackProps: StackProps) => {
  const { isSPMissing, isFetching } = useHasMissingLookup();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.COMPUTE' });

  if (!isSPMissing || isFetching) {
    return null;
  }

  return (
    <Stack {...stackProps}>
      <Alert
        size="M"
        variant="ghost"
        color="warning"
        subtitle={t('MISSING_SP_LOOKUP_MESSAGE')}
        icon={WarningAmberOutlined}
      />
    </Stack>
  );
};
