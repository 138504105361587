import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, FormControl, Stack, useTheme } from '@mui/material';

import { Card, Text } from '@verticeone/design-system';
import { Divider } from '@verticeone/design-system';
import { Grid } from '@verticeone/design-system';

import { SelectedVendorPanel } from '../shared/formFields/SelectedVendorPanel';
import { FormProductList } from '../shared/formFields/FormProductList';
import { createTypedTaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { FormData } from './schema';
import { TargetSignDateFormEntry } from '../shared/formFields/TargetSignDateFormEntry';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { DesiredContractLengthFormEntry } from '../shared/formFields/DesiredContractLengthFormEntry';
import { PurchasingManagerPermissions } from '../shared/formSections/PurchasingManagerPermissions';
import { PurchaseRequirements } from '../shared/formSections/PurchaseRequirements';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { CurrencyFormEntry } from '../shared/formFields/CurrencyFormEntry';
import { DepartmentsFormEntry } from '../shared/formFields/DepartmentsFormEntry';
import { MoneyFormEntry } from '../shared/formFields/MoneyFormEntry';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';
import { VerticePurchaseNegotiationThresholdSection } from './formSections/VerticePurchaseNegotiationThresholdSection';
import { usePagesContext } from '../../pages/PagesContext';
import { FormPage } from '../../pages/FormPage';
import { PurchasingManagerPermissionsV2 } from '../shared/formSections/PurchasingManagerPermissionsV2';
import FormChecklistItemField from '../../../../../../forms/fields/FormChecklistItemField';
import { useTaskFormContext } from '../shared/TaskFormContext';
import { DocumentsSection } from '../shared/formSections/DocumentsSection';
import { useTaskContext } from '../../../TaskContext';
import { usePurchaseVerticeNegotiationEligibility } from './formHooks/usePurchaseVerticeNegotiationEligibility';

export const RequirementsGatheringFormEntry = createTypedTaskFormEntry<FormData>();

const VendorAndProducts = () => {
  const { palette } = useTheme();
  return (
    <Card>
      <Box bgcolor={palette.core.color1} p={4}>
        <SelectedVendorPanel />
      </Box>
      <Divider />
      <FormProductList noBorder />
    </Card>
  );
};

type ContractDetailsProps = {
  parentAccountId?: string;
  contractCurrency?: string;
};

const ContractDetails: FC<ContractDetailsProps> = ({ parentAccountId, contractCurrency }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={6}>
      <DepartmentsFormEntry width={12} overriddeAccountId={parentAccountId} />
      <TargetSignDateFormEntry width={6} />
      <DesiredContractLengthFormEntry />
      <MoneyFormEntry<FormData>
        name="approvedBudget"
        label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.LABELS.APPROVED_BUDGET')}
        currency={contractCurrency}
      />
      <CurrencyFormEntry<FormData> name="contractCurrency" />

      <Grid item xs={12}>
        <VerticePurchaseNegotiationThresholdSection />
      </Grid>

      <RequirementsGatheringFormEntry
        name="signingEntity"
        label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
        tooltip={{
          title: t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY'),
          content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNING_ENTITY'),
        }}
        component={FormTextField}
        width={12}
        componentProps={{
          placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNING_ENTITY'),
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
      />
    </Grid>
  );
};

export const RequirementsGatheringForm = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<FormData>();
  const { readOnly } = useTaskFormContext();
  const { hasPages } = usePagesContext();
  const { taskOverview } = useTaskContext();
  const requestId = taskOverview?.request?.ref.split('/').pop();
  const parentAccountId = useWatch<FormData, 'parentAccountId'>({ name: 'parentAccountId' }) ?? undefined;
  const contractCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' }) ?? undefined;
  const internalNegotiationRequested = useWatch({ name: 'internalNegotiationRequested' });
  const verticeNegotiationRequested = useWatch({ name: 'verticeNegotiationRequested' });
  const { data: accountSettings } = useAccountSettings(parentAccountId || undefined);

  const negotiationEligibility = usePurchaseVerticeNegotiationEligibility();
  const withVertice =
    (Boolean(negotiationEligibility?.eligibleForVerticeNegotiation) && !Boolean(internalNegotiationRequested)) ||
    Boolean(verticeNegotiationRequested);

  useEffect(() => {
    const defaultCurrency = accountSettings?.preferredCurrency;
    if (defaultCurrency && !contractCurrency) {
      setValue('contractCurrency', defaultCurrency);
    }
  }, [accountSettings, setValue, contractCurrency]);

  if (hasPages) {
    return (
      <>
        <FormPage pageId="vendor_and_products">
          <Stack gap={6}>
            <VendorAndProducts />
            <FormControl variant="outlined">
              <FormChecklistItemField
                name="piiExpectedToBeHeld"
                label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PII_TO_BE_HELD_USER_LABEL')}
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                size="S"
                disabled={readOnly}
              />
            </FormControl>
          </Stack>
        </FormPage>
        <FormPage pageId="documents">
          {requestId ? (
            <Stack gap={4}>
              <Stack gap={2}>
                <Text variant="caption" size="XS" color="text2">
                  {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.DOCUMENT_UPLOAD')}
                </Text>
                <Text variant="body-regular" size="S" color="text3">
                  {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.DOCUMENT_UPLOAD_DESCRIPTION')}
                </Text>
              </Stack>
              {requestId ? <DocumentsSection /> : null}
            </Stack>
          ) : null}
        </FormPage>
        <FormPage pageId="contract_details">
          <Stack gap={6}>
            <ContractDetails parentAccountId={parentAccountId} contractCurrency={contractCurrency} />
            <Grid container>
              <AdditionalNotesFormEntry />
            </Grid>
          </Stack>
        </FormPage>
        <FormPage pageId="contact_and_permissions">
          <Grid container>
            <PurchasingManagerPermissionsV2 withVertice={withVertice} />
          </Grid>
        </FormPage>
      </>
    );
  }

  return (
    <Stack gap={6}>
      <VendorAndProducts />
      <ContractDetails parentAccountId={parentAccountId} contractCurrency={contractCurrency} />
      <Grid container>
        <PurchaseRequirements />
      </Grid>
      <Grid container>
        <AdditionalNotesFormEntry />
      </Grid>
      <Grid container>
        <PurchasingManagerPermissions />
      </Grid>
    </Stack>
  );
};
