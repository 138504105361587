import { FC } from 'react';
import { Button } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';
import { TaskColorVariant } from './types';
import { useFitTaskNodeIntoView } from './useFitTaskNodeIntoView';

type TaskViewButtonProps = {
  taskId: string;
  assigneeIds?: string[];
  colorVariant: TaskColorVariant;
  isHidden: boolean;
  nodeId: string;
};

export const TaskViewButton: FC<TaskViewButtonProps> = ({ taskId, assigneeIds, colorVariant, isHidden, nodeId }) => {
  const { t } = useTranslation();

  const { onOpenTask, loggedUserId } = useWorkflowRendererContext();
  const isAssigned = assigneeIds?.includes(loggedUserId);
  const { fitTaskNodeIntoView } = useFitTaskNodeIntoView();

  /*
   * Display the button only for tasks in states:
   * - active, but not assigned: neutral
   * - active, assigned: secondary
   * - completed, assigned: success
   * - failed: error
   */
  if (!Array<TaskColorVariant>('error', 'secondary', 'success', 'neutral').includes(colorVariant) || isHidden) {
    return null;
  }

  // If the task is completed and not assigned it is sub process task, do not show the button
  if (assigneeIds?.length === 0 && colorVariant === 'success') {
    return null;
  }

  // If the task is failed and not assigned to the current user, do not show the button
  if (!isAssigned && colorVariant === 'error') {
    return null;
  }

  const handleClick = () => {
    onOpenTask?.(taskId);
    fitTaskNodeIntoView(nodeId);
  };

  return (
    <Stack marginTop={2}>
      <Button onClick={handleClick} variant="ghost" size="XS" color={colorVariant}>
        {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.VIEW_TASK')}
      </Button>
    </Stack>
  );
};
