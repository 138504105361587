import { useShoppingCart } from '../../providers/ShoppingCartProvider';
import { calculateShoppingCartUnits, calculateRecommendedShoppingCartUnits } from '../utils';
import { ReactNode, useEffect } from 'react';
import { GraphContainerProps } from '../../../product/SageMaker/Graph/Graph';
import { useOfferings } from '../../providers/OfferingsProvider';
import { useInstanceData } from '../../providers/InstanceProvider';

type BuySPDrawerGraphProps = {
  chart: ({ shoppingCartUnits }: GraphContainerProps) => ReactNode;
};

const BuySPDrawerGraph = ({ chart }: BuySPDrawerGraphProps) => {
  const { offerings } = useOfferings({ filterByShoppingCart: false });
  const { cartItems, filterByShoppingCart } = useShoppingCart();
  const { item } = useInstanceData();

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const recommendedOffering = offerings.data?.[0];
  const hourlyRecommendation = item.to_cover_hourly_recommendation;

  const shoppingCartUnits = filterByShoppingCart
    ? calculateShoppingCartUnits(cartItems, hourlyRecommendation)
    : calculateRecommendedShoppingCartUnits(recommendedOffering, hourlyRecommendation);

  const ChartComponent = chart;

  return <ChartComponent shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={true} />;
};

export default BuySPDrawerGraph;
