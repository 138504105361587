import { Stack } from '@mui/material';
import { AvatarStack } from '@verticeone/design-system';
import { parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { Tooltip } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { AssigneesSkeletonCell } from './SkeletonCells';
import { useResolveUsersContextInfo } from '../../../../../../hooks/useResolveUsersInfo';
import { useMemo } from 'react';
import TwoRowCell from './TwoRowCell';
import { RequestRow } from '../../types';

type AssigneesCellProps = {
  assignees?: string[];
  activeTasks: RequestRow['activeTasks'];
};

export const AssigneesCell = ({ assignees, activeTasks }: AssigneesCellProps) => {
  const { t } = useTranslation();
  const isManagedService = activeTasks?.every((task) => task.managed && task.type === 'SERVICE');

  const parsedAssignees = useMemo(() => assignees?.map((assignee) => parseUserRef(assignee).userId) || [], [assignees]);
  const { getFullName, isLoading } = useResolveUsersContextInfo(parsedAssignees);

  if (isLoading) {
    return <AssigneesSkeletonCell />;
  } else if (!parsedAssignees || parsedAssignees.length === 0) {
    return (
      <TwoRowCell
        color={isManagedService ? 'text1' : 'error1'}
        title={isManagedService ? '-' : t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.NO_ASSIGNEES')}
      />
    );
  }

  return (
    <Stack>
      <Tooltip
        title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.ASSIGNEE_COUNT', { count: parsedAssignees.length })}
        size="S"
        placement="bottom"
        content={
          <Stack direction="column">
            {parsedAssignees.map((assignee) => (
              <span key={assignee}>{getFullName(assignee)}</span>
            ))}
          </Stack>
        }
      >
        <Stack width="fit-content">
          <AvatarStack
            size="S"
            shape="circle"
            maxItems={3}
            disableTooltip
            items={parsedAssignees.map((assignee) => ({
              personId: assignee,
              personName: getFullName(assignee),
              color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
            }))}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
