import { AddOutlined } from '@mui/icons-material';
import { Box, Stack, styled } from '@mui/material';
import { FieldArray, FieldArrayPath, FieldValues, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconButton, NoData, NoDataButton } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useCallback } from 'react';
import { JiraIssueField, WorkflowVariables } from '../types';
import { VariableMapperHeader } from './VariableMapperHeader';
import { VariableMapperRow } from './VariableMapperRow';
import { VariableMapperRowFormData } from './formSchema';

export const DEFAULT_MAPPING_TYPE = 'JMESPathExpression';

const NoDataWrapper = styled(Box)({
  height: '100%',
});

const VariableMapperBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  backgroundColor: theme.palette.core.color1,
  borderRadius: 12,
  padding: theme.spacing(4),
}));

export type VariableMapperProps<T extends FieldValues> = {
  isIntegrationActive: boolean;
  formFieldName: FieldArrayPath<T>;
  requiredToFieldsCount: number;
  fromWorkflow: boolean; // To distinguish the direction of mapping
  workflowVariables: WorkflowVariables;
  jiraVariables: JiraIssueField[];
  otherVariablesTitle: string;
};

export const VariableMapper = <T extends FieldValues>({
  isIntegrationActive,
  formFieldName,
  jiraVariables,
  requiredToFieldsCount,
  fromWorkflow,
  workflowVariables,
  otherVariablesTitle,
}: VariableMapperProps<T>) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const navigateToSetup = () => {
    navigate(routes.PREFERENCES.TAB.JIRA, { activeTab: 'integrations' });
  };

  const { fields, append, remove } = useFieldArray<T>({
    name: formFieldName,
  });

  const handleRemoveRow = useCallback((index: number) => remove(index), [remove]);

  const handleAddRow = () => {
    const newOption = { from: '', to: '', mappingType: DEFAULT_MAPPING_TYPE } as FieldArray<T, typeof formFieldName>;
    append(newOption);
  };

  if (!isIntegrationActive) {
    return (
      <NoDataWrapper>
        <NoData
          header={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_INTEGRATION_HEADER')}
          content={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_INTEGRATION_CONTENT')}
          button={
            <NoDataButton
              text={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_INTEGRATION_BUTTON')}
              onClick={navigateToSetup}
            />
          }
        />
      </NoDataWrapper>
    );
  }

  if (jiraVariables.length === 0) {
    return (
      <NoDataWrapper>
        <NoData
          header={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_FIELDS_HEADER')}
          content={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_FIELDS_CONTENT')}
        />
      </NoDataWrapper>
    );
  }

  return (
    <VariableMapperBox>
      <VariableMapperHeader
        worflowVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.VERTICE')}
        otherVariablesTitle={otherVariablesTitle}
        fromWorkflow={fromWorkflow}
      />

      {fields.map((field, index) => {
        const rowField = field as unknown as VariableMapperRowFormData;
        return (
          <VariableMapperRow
            field={rowField}
            index={index}
            key={field.id}
            fromWorkflow={fromWorkflow}
            formFieldPrefix={formFieldName}
            canRemove={index >= requiredToFieldsCount}
            onRemove={handleRemoveRow}
            workflowVariables={workflowVariables}
            jiraVariables={jiraVariables}
          />
        );
      })}

      <IconButton
        icon={AddOutlined}
        variant="outline"
        size="S"
        color="neutral"
        onClick={handleAddRow}
        aria-label={t('ENTITIES.CONTRACT.LABELS.LIST_FILTERS.NEW_VIEW')}
      />
    </VariableMapperBox>
  );
};
