import { useTranslation } from 'react-i18next';
import { useTaskContext } from '../../../TaskContext';
import { FormControl, Grid, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { DocumentsSection } from '../shared/formSections/DocumentsSection';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';
import { TargetSignDateFormEntry } from '../shared/formFields/TargetSignDateFormEntry';
import { FormData } from './schema';
import { CurrencyFormEntry } from '../shared/formFields/CurrencyFormEntry';
import { DesiredContractLengthFormEntry } from '../shared/formFields/DesiredContractLengthFormEntry';
import { MoneyFormEntry } from '../shared/formFields/MoneyFormEntry';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectedContractPanel } from '../shared/formFields/SelectedContractPanel';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';
import { RenewalTypeFormEntry } from '../shared/formFields/RenewalTypeFormEntry';
import { FormProductList } from '../shared/formFields/FormProductList';
import { Card, Text } from '@verticeone/design-system';
import { PurchaseRequirements } from '../shared/formSections/PurchaseRequirements';
import { PurchasingManagerPermissions } from '../shared/formSections/PurchasingManagerPermissions';
import { VendorContact } from '../shared/formSections/VendorContact';
import { VerticeRenewalNegotiationThresholdSection } from './formSections/VerticeRenewalNegotiationThresholdSection';
import { usePagesContext } from '../../pages/PagesContext';
import { FormPage } from '../../pages/FormPage';
import FormChecklistItemField from '../../../../../../forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { useTaskFormContext } from '../shared/TaskFormContext';
import { PurchasingManagerPermissionsV2 } from '../shared/formSections/PurchasingManagerPermissionsV2';
import { useRenewalVerticeNegotiationEligibility } from './formHooks/useRenewalVerticeNegotiationEligibility';

type VendorAndProductsPageContentProps = {
  parentAccountId?: string;
  cardStyle?: boolean;
};

const VendorAndProductsPageContent: FC<VendorAndProductsPageContentProps> = ({ parentAccountId, cardStyle }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={6}>
      <SelectedContractPanel overrideAccountId={parentAccountId} cardStyle={cardStyle} />
      <RenewalTypeFormEntry<FormData> name="renewalType" label={t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE')} />
      <Card>
        <FormProductList noBorder />
      </Card>
    </Stack>
  );
};

type ContractDetailsProps = {
  contractCurrency?: string;
};

const ContractDetails: FC<ContractDetailsProps> = ({ contractCurrency }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={6}>
      <TargetSignDateFormEntry width={6} />
      <DesiredContractLengthFormEntry />
      <MoneyFormEntry<FormData>
        name="approvedBudget"
        label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.APPROVED_BUDGET')}
        currency={contractCurrency}
      />
      <CurrencyFormEntry<FormData> name="contractCurrency" />
      <Grid item xs={12}>
        <VerticeRenewalNegotiationThresholdSection />
      </Grid>
    </Grid>
  );
};

export const RenewalRequirementsForm = () => {
  const { t } = useTranslation();
  const { taskOverview } = useTaskContext();
  const { readOnly } = useTaskFormContext();
  const requestId = taskOverview?.request?.ref.split('/').pop();
  const [contactVendorDisabled, setContactVendorDisabled] = useState(false);

  const { setValue } = useFormContext<FormData>();

  const parentAccountId = useWatch<FormData, 'parentAccountId'>({ name: 'parentAccountId' }) ?? undefined;
  const contractCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const mainVendorContact = useWatch<FormData, 'mainVendorContact'>({ name: 'mainVendorContact' });
  const internalNegotiationRequested = useWatch({ name: 'internalNegotiationRequested' });

  const negotiationEligibility = useRenewalVerticeNegotiationEligibility();
  const withVertice =
    Boolean(negotiationEligibility?.eligibleForVerticeNegotiation) && !Boolean(internalNegotiationRequested);

  const { data: accountSettings } = useAccountSettings(parentAccountId || undefined);
  useEffect(() => {
    const defaultCurrency = accountSettings?.preferredCurrency;
    if (defaultCurrency && !contractCurrency) {
      setValue('contractCurrency', defaultCurrency);
    }
  }, [accountSettings, setValue, contractCurrency]);

  useEffect(() => {
    if (!mainVendorContact) {
      setValue('contactVendorDirectly', true);
    }
    setContactVendorDisabled(!mainVendorContact);
  }, [mainVendorContact, setValue]);

  const { hasPages } = usePagesContext();
  if (hasPages) {
    return (
      <>
        <FormPage pageId="vendor_and_products">
          <Stack gap={6}>
            <VendorAndProductsPageContent parentAccountId={parentAccountId} cardStyle />
            <FormControl variant="outlined">
              <FormChecklistItemField
                name="piiExpectedToBeHeld"
                label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PII_TO_BE_HELD_USER_LABEL')}
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                size="S"
                disabled={readOnly}
              />
            </FormControl>
          </Stack>
        </FormPage>
        <FormPage pageId="documents">
          {requestId ? (
            <Stack gap={4}>
              <Stack gap={2}>
                <Text variant="caption" size="XS" color="text2">
                  {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.DOCUMENT_UPLOAD')}
                </Text>
                <Text variant="body-regular" size="S" color="text3">
                  {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.DOCUMENT_UPLOAD_DESCRIPTION')}
                </Text>
              </Stack>
              {requestId ? <DocumentsSection /> : null}
            </Stack>
          ) : null}
        </FormPage>
        <FormPage pageId="contract_details">
          <Stack gap={6}>
            <ContractDetails contractCurrency={contractCurrency} />
            <AdditionalNotesFormEntry />
          </Stack>
        </FormPage>
        <FormPage pageId="contact_and_permissions">
          <Stack gap={6}>
            <VendorContact />
            <PurchasingManagerPermissionsV2 contactVendorDisabled={contactVendorDisabled} withVertice={withVertice} />
          </Stack>
        </FormPage>
      </>
    );
  }
  return (
    <Stack gap={6}>
      <VendorAndProductsPageContent parentAccountId={parentAccountId} />
      <ContractDetails contractCurrency={contractCurrency} />
      <Grid item xs={12}>
        <PurchaseRequirements />
      </Grid>
      <AdditionalNotesFormEntry />
      {requestId ? <DocumentsSection /> : null}
      <VendorContact />
      <PurchasingManagerPermissions contactVendorDisabled={contactVendorDisabled} />
    </Stack>
  );
};
