import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { addMinutes, startOfDay } from 'date-fns';

import { Dialog, DialogContent, DialogHeader, DialogActions, DialogTextWrapper } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormSwitchField from '@vertice/core/src/modules/forms/fields/FormSwitchField';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { TextFieldCaption } from '@verticeone/design-system';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';

type EditExecutionDateModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  effectiveDate?: Date;
  executeImmediately: boolean;
  onChange: ({ effectiveDate, executeImmediately }: { effectiveDate: Date; executeImmediately: boolean }) => void;
};

type LayoutFormData = {
  effectiveDate: string;
  executeImmediately: boolean;
};

const LayoutFormEntry = createTypedFormEntry<LayoutFormData>();

const EditExecutionDateModal = ({
  open,
  setOpen,
  effectiveDate,
  executeImmediately,
  onChange,
}: EditExecutionDateModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.EXECUTION_DAY_MODAL' });

  const defaultValues = useMemo(
    () => ({
      executeImmediately,
      effectiveDate: effectiveDate?.toISOString() ?? '',
    }),
    [effectiveDate, executeImmediately]
  );

  const minDate = keepOnlyLocalDatePart(dayjs().add(1, 'days'))!;
  const maxDate = keepOnlyLocalDatePart(dayjs().add(3, 'months'))!;
  const formMethods = useForm<LayoutFormData>({ defaultValues, mode: 'onChange' });
  const { reset, watch } = formMethods;

  const isEffectiveDateDisabled = watch('executeImmediately');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleSubmit = (data: LayoutFormData) => {
    onChange({
      effectiveDate: addMinutes(startOfDay(new Date(data.effectiveDate)), +1),
      executeImmediately: data.executeImmediately,
    });
    setOpen(false);
  };

  const handleDiscard = () => {
    reset(defaultValues);
    setOpen(false);
  };

  return (
    <Dialog open={open} size="M">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <DialogHeader>{t('TITLE')}</DialogHeader>
          <DialogContent>
            <DialogTextWrapper>
              <Stack direction="column" gap={4}>
                <LayoutFormEntry
                  name="effectiveDate"
                  required
                  component={FormDateField}
                  componentProps={{
                    minDate,
                    maxDate,
                    variant: 'solid',
                    label: t('EXECUTION_DATE'),
                  }}
                  disabled={isEffectiveDateDisabled}
                />
                <Stack direction="column" gap={2}>
                  <LayoutFormEntry
                    name="executeImmediately"
                    componentProps={{ label: t('EXECUTE_IMMEDIATELY') }}
                    component={FormSwitchField}
                  />
                  <TextFieldCaption size="S" label={t('EXECUTE_IMMEDIATELY_DESCRIPTION')} />
                </Stack>
              </Stack>
            </DialogTextWrapper>
          </DialogContent>
          <DialogActions>
            <Button testId="discard" color="tertiary" variant="outline" onClick={handleDiscard}>
              {t('FOOTER.DISCARD')}
            </Button>
            <Button testId="save" variant="solid" color="tertiary" type="submit">
              {t('FOOTER.SAVE')}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditExecutionDateModal;
