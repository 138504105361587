import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { Alert, AlertButton } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';

import { useFulfillUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

import { parseUserRef, parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useLoggedUser } from '@verticeone/auth/src';
import TaskDrawerForm from './TaskDrawerForm';
import { FORM_CONFIGURATION_KIND } from './constants';
import { useTaskContext } from './TaskContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

import {
  SAAS_APPROVAL_FORM_CONFIG,
  isSaaSApprovalTask,
} from './TaskDrawerForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { useSnackbar } from 'notistack';
import { TaskDrawerFormResult } from './TaskDrawerForm/TaskDrawerFormResult';

type TaskDrawerContentProps = {
  onCompleted: () => void;
};

export const TaskDrawerContent = ({ onCompleted }: TaskDrawerContentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { taskOverview } = useTaskContext();

  const [fulfillUserTask] = useFulfillUserTaskMutation();
  const [taskCompletionResult, setTaskCompletionResult] = useState<'SUCCESS' | 'ERROR' | undefined>();

  const handleCompleteTask = useCallback(
    async (resultBody: object) => {
      if (taskOverview) {
        const response = await fulfillUserTask({
          accountId,
          taskId: parseUserTaskRef(taskOverview.task.ref).taskId,
          body: resultBody,
        });

        if ('error' in response) {
          setTaskCompletionResult('ERROR');
        } else {
          setTaskCompletionResult('SUCCESS');

          enqueueSnackbar(
            t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_SUCCESS', { taskName: taskOverview?.task.name }),
            {
              variant: 'success',
            }
          );

          onCompleted();
        }
      }
    },
    [accountId, enqueueSnackbar, fulfillUserTask, onCompleted, t, taskOverview]
  );

  const formConfig = isSaaSApprovalTask(taskOverview)
    ? SAAS_APPROVAL_FORM_CONFIG
    : taskOverview?.task.configurations?.find((configuration) => configuration.kind === FORM_CONFIGURATION_KIND);
  const currentUserAssigned = taskOverview?.task.assignment?.some(
    (assignment) => parseUserRef(assignment).userId === userId
  );

  if (!taskOverview) {
    return <Loader size={20} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />;
  }

  const taskCompleted = taskOverview.task.status === 'COMPLETED';

  if (!formConfig || (!currentUserAssigned && !taskCompleted)) {
    return null;
  }

  return (
    <Stack minHeight={0} overflow="hidden" height={'100%'}>
      {/*completion error*/}
      {taskCompletionResult === 'ERROR' && (
        <Stack p={6}>
          <Alert
            size="S"
            variant="ghost"
            color="error"
            title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETION_FAILED')}
          >
            <AlertButton onClick={() => setTaskCompletionResult(undefined)}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
          </Alert>
        </Stack>
      )}

      {/*editable task form*/}
      {taskCompletionResult === undefined && !taskCompleted && (
        <Stack gap={4} minHeight={0} height="100%">
          <TaskDrawerForm onSubmit={handleCompleteTask} onCancel={() => null} formConfig={formConfig} />
        </Stack>
      )}

      {/*read-only task form*/}
      {taskCompletionResult === undefined && taskCompleted && (
        <Stack gap={4} p={6} alignItems="center" overflow="auto">
          <Text variant="heading" size="M">
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETE_HEADING')}
          </Text>
          <TaskDrawerFormResult formConfig={formConfig} />
        </Stack>
      )}
    </Stack>
  );
};
