import { FC } from 'react';
import { CommentsBox, MentionUser } from '@verticeone/design-system';
import { useConversations } from '../../useConversations';
import { AccountUser } from '@vertice/slices';

type CommentPanelProps = {
  requestId: string;
  accountId: string;
  canEditComents: boolean;
  requestOwner?: string;
  userId: string;
  usersById: {
    [x: string]: AccountUser | MentionUser;
  };
  isLoading?: boolean;
};
export const CommentPanel: FC<CommentPanelProps> = ({
  accountId,
  requestId,
  requestOwner,
  usersById,
  canEditComents,
  userId,
  isLoading,
}) => {
  const {
    messages,
    cursor,
    sendMessage,
    removeMessage,
    updateCursor,
    editMessage,
    isLoading: isLoadingConversation,
    isMessagePending,
  } = useConversations(requestId, accountId, requestOwner);

  return (
    <CommentsBox
      users={usersById}
      currentUser={userId}
      canEditComents={canEditComents}
      comments={messages}
      cursor={cursor}
      onSendComment={sendMessage}
      onRemoveComment={removeMessage}
      onUpdateCursor={updateCursor}
      onEditComment={editMessage}
      isLoading={isLoading || isLoadingConversation}
      isSending={isMessagePending}
    />
  );
};
