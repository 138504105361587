import { Dialog, DialogHeader, DialogContent, DialogText, DialogActions } from '@verticeone/design-system';
import { Button, IconButton, Divider } from '@verticeone/design-system';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import VendorSelect from '../../../../../vendor/VendorSelect';
import { Vendor } from '../../../../../vendor/types';
import { useCreateRenewalRequest } from '../../../../hooks/useCreateRenewalRequest';
import { ContractSelect } from '../../../../../saas/contract/components/ContractSelect/ContractSelect';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { useServiceCards } from '../../../../service/useServiceCards';
import { isSaasNewPurchaseServiceRef, isSaasRenewalServiceRef } from '../../../../../../hooks/workflows/refUtils';
import { contractVendorToVendor } from '../../../../../vendor/utils';
import { ContractOption } from '../../../../../saas/contract/components/ContractSelect/types';
import { useCreateNewPurchaseRequest } from '../../../../hooks/useCreateNewPurchaseRequest';
import { ProgressDialogContent } from './ProgressDialogContent';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { FEATURES } from '../../../../../features/constants';
import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { mapContractProductsToRenewalProducts, mapCostModelProductsToRenewalProducts } from '../../../../utils';

type CreateRequestDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onReset: () => void;
  autoStart?: boolean;
  defaultVendor?: Vendor;
  defaultContract?: Contract;
};

type DialogState = 'INITIAL' | 'PURCHASE' | 'RENEWAL' | 'PROGRESS';

const CreateRequestDialogInstance: FC<CreateRequestDialogProps> = ({
  open,
  setOpen,
  onReset,
  defaultVendor,
  defaultContract,
  autoStart,
}) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  const [dialogState, setDialogState] = useState<DialogState>(() => {
    if (defaultContract) {
      return 'RENEWAL';
    } else if (defaultVendor) {
      return 'PURCHASE';
    } else {
      return 'INITIAL';
    }
  });

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(defaultVendor ?? null);
  const [selectedContract, setSelectedContract] = useState<ContractOption | null>(
    defaultContract
      ? {
          contract: defaultContract,
          id: defaultContract.record.contractId,
        }
      : null
  );
  const isInInitialState = dialogState === 'INITIAL';

  const {
    createNewPurchaseRequest,
    isCreatingNewPurchaseRequest,
    createdNewPurchaseRequestId,
    createdNewPurchaseRequestTaskId,
  } = useCreateNewPurchaseRequest();

  const { createRenewalRequest, isCreatingRenewalRequest, createdRenewalRequestId, createdRenewalRequestTaskId } =
    useCreateRenewalRequest();

  const isCreatingRequest = isCreatingRenewalRequest || isCreatingNewPurchaseRequest;
  const createdRequestId = createdNewPurchaseRequestId || createdRenewalRequestId;
  const createdRequestTaskId = createdNewPurchaseRequestTaskId || createdRenewalRequestTaskId;

  const purchaseReady = dialogState === 'PURCHASE' && selectedVendor;
  const renewalReady = dialogState === 'RENEWAL' && selectedContract;

  const serviceCards = useServiceCards({
    filter: (service) => isSaasNewPurchaseServiceRef(service.urn) || isSaasRenewalServiceRef(service.urn),
    onClick: (serviceRef) => {
      if (isSaasNewPurchaseServiceRef(serviceRef)) {
        setDialogState('PURCHASE');
      }
      if (isSaasRenewalServiceRef(serviceRef)) {
        setDialogState('RENEWAL');
      }
    },
  });

  const vendorName =
    (selectedVendor ? selectedVendor.name : selectedContract?.contract.parts.contractual?.vendor?.vendorName) ?? '';

  const handleCreateRequest = useCallback(() => {
    setDialogState('PROGRESS');
    if (selectedVendor) {
      void createNewPurchaseRequest({ vendor: selectedVendor });
    } else if (selectedContract) {
      const contractVendor = selectedContract.contract.parts.contractual?.vendor;
      if (contractVendor && selectedContract.id) {
        const products = advancedCostModels
          ? mapCostModelProductsToRenewalProducts(
              selectedContract.contract.parts.model?.cost?.configuration?.negotiated
            )
          : mapContractProductsToRenewalProducts(selectedContract.contract.parts.contractual?.products);

        void createRenewalRequest({
          vendor: contractVendorToVendor(contractVendor),
          products: products,
          contractId: selectedContract.id,
          department: selectedContract.contract.classification?.departmentId,
        });
      }
    }
  }, [createNewPurchaseRequest, createRenewalRequest, selectedContract, selectedVendor, advancedCostModels]);

  useEffect(() => {
    if (autoStart) {
      handleCreateRequest();
    }
  }, [autoStart, handleCreateRequest]);

  const goToRequestDetails = useCallback(
    (openDrawerForTask?: string) => {
      if (createdRequestId) {
        navigate(
          routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL,
          {
            requestId: createdRequestId,
          },
          { search: openDrawerForTask ? { taskId: openDrawerForTask } : undefined }
        );
      }
    },
    [createdRequestId, navigate, routes]
  );

  useEffect(() => {
    if (createdRequestTaskId) {
      goToRequestDetails(createdRequestTaskId);
    }
  }, [createdRequestId, createdRequestTaskId, goToRequestDetails]);

  const showCloseButton = isInInitialState || (dialogState === 'PROGRESS' && !isCreatingRequest);

  return (
    <Dialog
      open={open}
      setOpen={showCloseButton ? setOpen : undefined}
      size="M"
      width={780}
      onClose={() => dialogState !== 'PROGRESS' && setOpen(false)}
    >
      <DialogHeader>
        {dialogState !== 'PROGRESS' && (
          <Stack gap={4} direction="row" alignItems="center">
            {!isInInitialState && (
              <IconButton variant="outline" icon={ChevronLeftOutlined} onClick={() => setDialogState('INITIAL')} />
            )}
            {t(`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.HEADING_${dialogState}`)}
          </Stack>
        )}
      </DialogHeader>
      {dialogState !== 'PROGRESS' && <Divider />}
      <DialogContent>
        <Stack gap={4} minHeight={isInInitialState ? 300 : 219}>
          {/* 219 because divider is 1px, 80px is the footer (actions) */}
          {dialogState !== 'PROGRESS' && (
            <DialogText variant="body-regular">
              <Trans
                i18nKey={`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.DESCRIPTION_${dialogState}`}
                components={{ break: <br /> }}
              />
            </DialogText>
          )}

          {isInInitialState && (
            <Stack direction="row" gap={2}>
              {serviceCards}
            </Stack>
          )}
          {dialogState === 'PURCHASE' && (
            <VendorSelect
              value={selectedVendor}
              onChange={(v) => setSelectedVendor(v)}
              isDisabled={isCreatingNewPurchaseRequest}
            />
          )}
          {dialogState === 'RENEWAL' && (
            <ContractSelect
              isDisabled={isCreatingRenewalRequest}
              value={selectedContract}
              onChange={(c) => setSelectedContract(c)}
              optionsPermissionFilter={(permissions) => permissions.userCanExecuteContract}
            />
          )}
          {dialogState === 'PROGRESS' && (
            <ProgressDialogContent
              isCreatingRequest={isCreatingRequest}
              vendorName={vendorName}
              onGoToRequestDetails={() => goToRequestDetails()}
              onCreateNewRequest={onReset}
              requestType={selectedVendor ? 'PURCHASE' : 'RENEWAL'}
            />
          )}
        </Stack>
      </DialogContent>
      <Divider />
      {(dialogState === 'PURCHASE' || dialogState === 'RENEWAL') && (
        <DialogActions>
          <Button
            variant="outline"
            disabled={isCreatingRequest}
            onClick={() => setOpen(false)}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('DIALOG.BUTTONS.CANCEL')}
          </Button>
          <Button
            variant="solid"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            onClick={handleCreateRequest}
            disabled={isCreatingRequest || (!purchaseReady && !renewalReady)}
            isLoading={isCreatingRequest}
          >
            {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.START_REQUEST_BUTTON')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export const CreateRequestDialog = ({ autoStart, ...restProps }: Omit<CreateRequestDialogProps, 'onReset'>) => {
  const [instanceNumber, setInstanceNumber] = useState(0);
  const isFirstInstance = instanceNumber === 0;
  return (
    <CreateRequestDialogInstance
      autoStart={isFirstInstance ? autoStart : undefined}
      {...restProps}
      key={instanceNumber}
      onReset={() => setInstanceNumber(instanceNumber + 1)}
    />
  );
};
