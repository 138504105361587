import { useMemo, useState, FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Text } from '@verticeone/design-system';

import type { ProcessDefinition, TaskDefinition, TasksThumbnail } from '../../definitionsTypes';
import { TaskIcon } from '../../sharedVisualStyle/taskStyle';
import { resolveTaskNodeThumbnailConfiguration } from '../model/resolveTaskNodeThumbnailConfiguration';
import {
  getTaskAssignmentConfigurations,
  replaceConfigurationInTaskDefinition,
  updateTaskDefinition,
} from '../../definitions/taskDefinition';

import { EditUserTaskForm } from './forms/EditUserTaskForm/EditUserTaskForm';
import { EditUserTaskFormData, editUserTaskFormSchema } from './forms/EditUserTaskForm/schema';
import {
  getAssignmentConfigurationsFromAssignees,
  getAssigneesFromAssignmentConfigurations,
} from './forms/EditUserTaskForm/assignmentOptions';
import { EditorDrawer } from './EditorDrawer';
import useAddEditInviteContactModal from '../../../preferences/useAddEditInviteContactModal';
import { AvailableVariablesDialog } from './AvailableVariablesDialog';
import { IOMapping } from './forms/EditUserTaskForm/ioMapping';
import { isTaskIOMappingConfiguration, isVerticeFormConfiguration } from '../../pocWorkflowSchema';
import { getObjectFromJMESPathExpression, toJMESPathExpression } from './utils';

export type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

type EditUserTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  close: VoidFunction;
  saveTask: (task: TaskDefinition) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
  isAvailableVariablesDialogEnabled?: boolean;
};

type EditUserTaskDrawerHeaderProps = {
  text: string;
  thumbnail?: TasksThumbnail;
};

const EditUserTaskDrawerHeader: FC<EditUserTaskDrawerHeaderProps> = ({ text, thumbnail }) => {
  if (!thumbnail) return null;

  return (
    <Stack direction="row" gap={2} alignItems={'center'} p={6}>
      <TaskIcon userId={''} status={'PENDING'} type={thumbnail.type} id={thumbnail.id} />
      <Text variant="heading" size="S" color="text1">
        {text}
      </Text>
    </Stack>
  );
};

const EditUserTaskDrawerContent: FC<Omit<Required<EditUserTaskDrawerProps>, 'isOpen'>> = ({
  isAvailableVariablesDialogEnabled,
  onDirty,
  saveTask,
  task,
  close,
  workflowServiceRef,
  processDefinition,
  editorConfig,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [isVariablesDialogOpen, setIsVariablesDialogOpen] = useState(false);

  const values = useMemo<EditUserTaskFormData>(() => {
    if (task === undefined) {
      return {
        name: '',
        assignees: [],
        description: '',
        buttonLabels: undefined,
        formHeading: undefined,
      };
    }

    const assignmentConfigurations = getTaskAssignmentConfigurations(task);
    const ioMapping = task.task.configurations?.find(isTaskIOMappingConfiguration);

    const buttonLabelsParsedExpression = getObjectFromJMESPathExpression<EditUserTaskFormData['buttonLabels']>(
      ioMapping?.mapping.inputFields.find((field) => field.name === 'buttonLabels')?.value
    );
    const formHeadingParsedExpression = getObjectFromJMESPathExpression<EditUserTaskFormData['formHeading']>(
      ioMapping?.mapping.inputFields.find((field) => field.name === 'formHeading')?.value
    );

    return {
      name: task.task.name || '',
      assignees: getAssigneesFromAssignmentConfigurations(assignmentConfigurations),
      description: task.task.description || '',
      buttonLabels: buttonLabelsParsedExpression,
      formHeading: formHeadingParsedExpression,
    };
  }, [task]);

  const formConfiguration = task?.task.configurations?.find(isVerticeFormConfiguration);

  const handlers = useForm<EditUserTaskFormData>({
    resolver: zodResolver(editUserTaskFormSchema),
    defaultValues: values,
    values,
  });

  const taskConfigurations = task.task.configurations;
  const thumbnail = taskConfigurations ? resolveTaskNodeThumbnailConfiguration(taskConfigurations) : undefined;

  const onSubmit = (data: EditUserTaskFormData) => {
    const newAssignmentConfigurations = getAssignmentConfigurationsFromAssignees(data.assignees);

    const ioMapping = task.task.configurations?.find(isTaskIOMappingConfiguration);

    const newMapping = new IOMapping(ioMapping);

    if (ioMapping) {
      if (data.buttonLabels) newMapping.addInputMapping('buttonLabels', toJMESPathExpression(data.buttonLabels));
      if (data.formHeading) newMapping.addInputMapping('formHeading', toJMESPathExpression(data.formHeading));
    }

    const taskWithIOMapping = replaceConfigurationInTaskDefinition(task, newMapping.getIOMappingConfiguration());

    const newTask = updateTaskDefinition(taskWithIOMapping, {
      name: data.name,
      description: data.description,
      assignmentConfigurations: newAssignmentConfigurations,
    });

    saveTask(newTask);
  };

  const { modal, handleAddRequest } = useAddEditInviteContactModal({
    adminMode: false,
    warningMessage: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.NEW_USER_WARNING'),
  });

  return (
    <>
      <EditUserTaskDrawerHeader text={task.task.name || ''} thumbnail={thumbnail} />
      <Stack maxHeight="100%" height="100%" width="100%">
        <FormProvider {...handlers}>
          <form style={{ width: '100%', height: '100%' }} onSubmit={handlers.handleSubmit(onSubmit)}>
            <Stack direction="column" justifyContent="space-between" height="100%" width="100%">
              <Stack
                direction="column"
                justifyContent="space-between"
                px={6}
                pb={6}
                overflow="auto"
                height="100%"
                width="100%"
              >
                <EditUserTaskForm
                  onDirty={onDirty}
                  onAddUser={handleAddRequest}
                  taskName={task?.task.name}
                  formConfiguration={formConfiguration}
                  allowContractOwnerAssignment={editorConfig.allowContractOwnerAssignment}
                />
                {isAvailableVariablesDialogEnabled && (
                  <Button
                    onClick={() => setIsVariablesDialogOpen(true)}
                    variant="outline"
                    color="secondary"
                    size="S"
                    fullWidth
                  >
                    {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY.DIALOG.AVAILABLE_VARIABLES')}
                  </Button>
                )}
              </Stack>
              <Stack gap={1} p={6} direction={'row'} borderTop={`1px solid ${palette.core.color3}`}>
                <Button onClick={close} variant="outline" color="secondary" size="S" fullWidth>
                  {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.DISCARD')}
                </Button>
                <Button variant="solid" color="secondary" size="S" fullWidth type="submit">
                  {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.APPLY')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
        {modal}
        {isVariablesDialogOpen && isAvailableVariablesDialogEnabled && (
          <AvailableVariablesDialog
            processDefinition={processDefinition}
            isOpened={isVariablesDialogOpen}
            onClose={() => setIsVariablesDialogOpen(false)}
            nodeId={task.task.id}
            workflowServiceRef={workflowServiceRef}
          />
        )}
      </Stack>
    </>
  );
};

export const EditUserTaskDrawer = ({
  isOpen,
  task,
  close,
  saveTask,
  onDirty,
  editorConfig,
  processDefinition,
  workflowServiceRef,
  isAvailableVariablesDialogEnabled = false,
}: EditUserTaskDrawerProps) => {
  return (
    <EditorDrawer open={isOpen}>
      {task && processDefinition && workflowServiceRef && (
        <EditUserTaskDrawerContent
          close={close}
          saveTask={saveTask}
          onDirty={onDirty}
          editorConfig={editorConfig}
          task={task}
          isAvailableVariablesDialogEnabled={isAvailableVariablesDialogEnabled}
          workflowServiceRef={workflowServiceRef}
          processDefinition={processDefinition}
          key={task.task.id}
        />
      )}
    </EditorDrawer>
  );
};
