import { Offer } from '../../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { useOfferTotalCost } from '../../../../../hooks/useOfferTotalCost';
import dayjs from 'dayjs';

type UseOfferFooterProps = {
  offer: Offer;
};

type UseOfferFooterValuesReturn = {
  annualCost: number;
  totalCost: number;
  concessionSavings: number;
  offerCost: number;
  offerTermInMonths: number;
};

const calculateOfferTermInMonths = (offer: Offer) => {
  switch (offer.rollingFrequency) {
    case 'NO':
      const startDate = dayjs(offer.startDate);
      // plus 1 day is because often the end date is enter as inclusive and diff is exclusive
      const endDate = dayjs(offer.endDate ?? offer.startDate).add(1, 'day');
      return endDate.diff(startDate, 'month');
    case 'MONTHLY':
      return 1;
    case 'QUARTERLY':
      return 3;
    case 'BIANNUALLY':
      return 6;
    case 'ANNUALLY':
      return 12;
    default:
      return 0;
  }
};

export const useOfferFooterValues = ({ offer }: UseOfferFooterProps) => {
  const offerTotalCost = useOfferTotalCost(offer);

  const getOfferFooterValues = (): UseOfferFooterValuesReturn => {
    const annualCost =
      offer.overrideEffectiveCost && offer.effectiveCosts ? offer.effectiveCosts : offer.computedCosts ?? 0;
    const totalCost = offerTotalCost ?? 0;

    const concessionSavings = offer.concessions.reduce(
      (acc, concession) => (acc += concession.status === 'REALISED' ? concession.amount : 0),
      0
    );

    const offerCost = (offer.overrideEffectiveCost ? offer.effectiveCosts : offer.computedCosts) ?? 0;

    const offerTermInMonths = calculateOfferTermInMonths(offer);

    return {
      annualCost,
      totalCost,
      concessionSavings,
      offerCost,
      offerTermInMonths,
    };
  };

  return { getOfferFooterValues };
};
