import * as z from 'zod';
import { nonEmptyString } from '@verticeone/utils/validation';

export const editUserTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  assignees: z.array(z.string()),
  description: z.string().optional(),
  buttonLabels: z
    .object({
      approve: z.string(),
      reject: z.string(),
    })
    .nullish()
    .optional(),
  formHeading: z.string().nullish().optional(),
});

export type EditUserTaskFormData = z.infer<typeof editUserTaskFormSchema>;
