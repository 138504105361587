import React from 'react';
import { Grid, Stack, styled, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFormatPercentage, useFormatCurrency } from '@verticeone/utils/formatting';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import { StatsData } from './useSavingsPlanUtilizationData';
import { testProps } from '@verticeone/design-system';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

const StatsContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.core.bg,
}));

type StatsItemProps = {
  label: string;
  value: string;
  testId: string;
};

const StatsItem = ({ label, value, testId }: StatsItemProps) => {
  return (
    <Grid item md={3} xs={12} {...testProps(testId)}>
      <StatsContainer padding={6}>
        <StatsBody gap={4} isLoading={false} title={<Title>{label}</Title>} value={<Value>{value}</Value>} />
      </StatsContainer>
    </Grid>
  );
};

type StatsGridProps = {
  data?: StatsData;
};

const Stats = ({ data }: StatsGridProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SP_INVENTORY.DRAWER' });
  const formatPercentage = useFormatPercentage();
  const formatCurrency = useFormatCurrency();
  const { palette } = useTheme();

  return (
    <Stack>
      <Grid
        container
        item
        xs={12}
        spacing="1px"
        sx={{
          backgroundColor: palette.core.color3,
        }}
      >
        <Grid item md={3} xs={12}>
          <StatsContainer padding={6}>
            <StatsBody
              gap={4}
              isLoading={false}
              title=""
              value={
                <Stack gap={2}>
                  <Value>{t('STATS.TITLE')}</Value>
                  <Stack direction="row" gap={2} alignItems="center">
                    <IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color2} />
                    <Text variant="body-bold" size="S" color="text2">
                      {t('STATS.SUBTITLE')}
                    </Text>
                  </Stack>
                </Stack>
              }
            />
          </StatsContainer>
        </Grid>
        <StatsItem
          testId={'stats-item-total-consumption'}
          label={t('STATS.CONSUMPTION')}
          value={
            data?.totalConsumption !== undefined
              ? formatCurrency(data.totalConsumption, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 2 })
              : t('STATS.NA')
          }
        />
        <StatsItem
          testId={'stats-item-avg-utilization'}
          label={t('STATS.UTILIZATION')}
          value={
            data?.avgUtilization !== undefined
              ? formatPercentage(data.avgUtilization, { maximumFractionDigits: 0 })
              : t('STATS.NA')
          }
        />
        <StatsItem
          testId={'stats-item-total-savings'}
          label={t('STATS.SAVINGS')}
          value={
            data?.totalSavings !== undefined
              ? formatCurrency(data.totalSavings ?? 0, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 2 })
              : t('STATS.NA')
          }
        />
      </Grid>
    </Stack>
  );
};

export default Stats;
