import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { formatCurrency } from '@verticeone/utils/formatting';
import { Button } from '@verticeone/design-system';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { SpriAction } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

import MissingPermissionTooltip from './MissingPermissionTooltip';
import useAwsSpRioSubFeatures from '@vertice/dashboard/src/pages/Cloud/CloudRIO/useAwsSpRioSubFeatures';
import BuySPModal from './BuySPModal';
import { useOfferings } from '../../../../providers/OfferingsProvider';
import { useSPPurchasePermissionQuery } from '../../../../../dataSources/useSPPurchasePermissionQuery';

type TableFooterProps = {
  totalCost: number;
  isEmpty: boolean;
};

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    isEmpty: boolean;
    totalCost: number;
  }
}

const TableFooter = ({ totalCost, isEmpty }: TableFooterProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.FOOTER' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const { offerings } = useOfferings();
  const awsRioSubFeatures = useAwsSpRioSubFeatures();

  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const { isFetching: isSPPurchasePermissionFetching, data: isSPPurchaseAllowed } = useSPPurchasePermissionQuery({
    action: SpriAction.Purchase,
  });

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const isLoading = isSPPurchasePermissionFetching || awsRioSubFeatures.isFetching;

  return (
    <Stack
      sx={{
        borderTop: `1px solid ${palette.core.color2}`,
      }}
      direction="row"
      py={3}
      px={6}
      justifyContent="end"
      alignItems="center"
      gap={4}
    >
      <Stack direction="row" gap={2}>
        <Text variant="body-regular" color="text3">
          {t('COST')}
        </Text>
        <Text variant="body-bold">
          {formatCurrency(totalCost, {
            currency: AWS_DEFAULT_CURRENCY,
            locale,
            maximumFractionDigits: 2,
          })}
        </Text>
      </Stack>
      <Button
        testId="buy"
        variant="solid"
        color="tertiary"
        disabled={isEmpty || !isSPPurchaseAllowed}
        isLoading={isLoading}
        onClick={() => setIsBuyModalOpen(true)}
      >
        {t('BUY')}
      </Button>
      {!isSPPurchaseAllowed && <MissingPermissionTooltip />}
      <BuySPModal open={isBuyModalOpen} setOpen={setIsBuyModalOpen} />
    </Stack>
  );
};

export default TableFooter;
