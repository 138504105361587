import dayjs from 'dayjs';

import type { SpListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import type { ShoppingCartItemType } from '../components/providers/ShoppingCartProvider';
import { ComputeDataColumn, GenericDataColumn } from './useMonitoringLatestQuery';
import { OfferingData } from '../types';

export const parseSpOfferings = (
  data: SpListOfferingsQuery | undefined,
  instance: Record<ComputeDataColumn, number> | Record<GenericDataColumn, number>,
  cartItems?: ShoppingCartItemType[] | null,
  effectiveDate?: string
): OfferingData[] => {
  if (
    data?.spListOfferingsQuery?.__typename !== 'SPOfferingsResult' ||
    data?.spListOfferingsQuery?.items === undefined ||
    data?.spListOfferingsQuery?.items?.length === 0
  ) {
    return [];
  }

  const items = !!cartItems
    ? data.spListOfferingsQuery.items.filter((item) => cartItems?.some((cardItem) => cardItem.id === item.offeringId))
    : data?.spListOfferingsQuery?.items;

  return items.map(({ offeringId, durationSeconds, planType, paymentOption, currency }) => {
    const term = Math.round(dayjs.duration(durationSeconds * 1000).asMonths());
    const expectedSavings = instance.annual_savings_from * (term / 12);
    const totalCost = instance.to_cover_hourly_recommendation * (durationSeconds / 60 / 60);
    const upfrontCost = 0;
    const monthlyCost = totalCost / term;
    const startDate = effectiveDate || dayjs().add(72, 'hour').format('YYYY-MM-DD');

    return {
      offeringId,
      term,
      planType,
      hourlyCommitment: instance.to_cover_hourly_recommendation,
      paymentOption,
      totalCost,
      expectedSavings,
      upfrontCost, // todo we don't have data for this
      monthlyCost,
      currencyCode: currency,
      startDate,
      executeImmediately: 0,
    };
  });
};
