import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, LinkProps, Stack, styled, useTheme } from '@mui/material';
import { FC, forwardRef, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, To } from 'react-router-dom';

import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Button, Text } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { DEFINITION_VERSION } from '../../../../definitions/constants';
import { isIOMappingConfiguration, updateTaskIOMapping } from '../../../../definitions/taskDefinition';
import { FieldMapping, TaskIOMappingConfiguration } from '../../../../definitionsTypes';
import { EditServiceTaskForm } from '../../forms/EditServiceTask/EditServiceTaskForm/EditServiceTaskForm';
import { IssueTypePicker, IssueTypePickerProps } from '../JiraCommon/IssueTypePicker';
import { LoadingSkeleton } from '../JiraCommon/LoadingSkeleton';
import {
  applyPrefixToVariables,
  enhanceWithDefaultDestination,
  getJiraCreateInputMapping,
  ISSUE_TYPE_ID_NAME,
  PROJECT_ID_NAME,
  workflowToFormVariables,
} from '../JiraCommon/utils';
import { VariableMapper, VariableMapperProps } from '../JiraCommon/VariableMapper/VariableMapper';
import { EditServiceTaskBase } from '../types';
// import { Attachments } from './Attachments';
import { JiraCreateTaskFormData, jiraCreateTaskFormSchema } from './formSchema';
// import { randomId } from '@mui/x-data-grid-generator';

type StyledLinkProps = LinkProps & { to?: To };

export const StyledLink = styled(
  forwardRef<HTMLAnchorElement, StyledLinkProps>((props: StyledLinkProps, ref) => (
    <Link {...props} ref={props.ref ?? ref} />
  ))
)(({ theme }) => ({
  color: theme.palette.text.color3,
  textDecorationColor: theme.palette.text.color3,
  '&:hover': {
    textDecoration: 'none',
  },
}));

type JiraCreateFormProps = Pick<EditServiceTaskBase, 'task' | 'saveTask' | 'onDirty' | 'close'> & {
  taskService: ServiceCatalogResource;
  issueTypePickerProps: Omit<IssueTypePickerProps, 'defaultIssueTypeId'>;
  variableMapperProps: Omit<
    VariableMapperProps<JiraCreateTaskFormData>,
    'formFieldName' | 'requiredToFieldsCount' | 'otherVariablesTitle'
  >;
  projectId?: string;
  isFetchingIssueTypeFields: boolean;
};

// We know form has to always map some values into theseJira ticket fields
const requiredToFields = ['summary', 'description'];

export const JiraCreateForm: FC<JiraCreateFormProps> = ({
  task,
  taskService,
  projectId,
  isFetchingIssueTypeFields,
  saveTask,
  onDirty,
  close,
  issueTypePickerProps,
  variableMapperProps,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const { isIntegrationActive, isLoadingIntegration, isLoadingIssueTypes } = issueTypePickerProps;

  const { issueTypeId, /*ticketId, */ variables } = getJiraCreateInputMapping(task, taskService);

  const values = useMemo<JiraCreateTaskFormData>(() => {
    if (task === undefined) {
      return {
        name: '',
        description: '',
        issueType: '',
        variables: enhanceWithDefaultDestination([], requiredToFields),
        // TODO: Add attachments feature once it's fully supported on WF side
        // includeAttachments: false,
      };
    }

    let workflowVariables = workflowToFormVariables(variables);
    workflowVariables = enhanceWithDefaultDestination(workflowVariables, requiredToFields);

    return {
      name: task.task.name ?? '',
      description: task.task.description ?? '',
      issueType: issueTypeId ?? '',
      variables: workflowVariables,
      // TODO: Add attachments feature once it's fully supported on WF side
      // includeAttachments: includeAttachments ?? true,
    };
  }, [issueTypeId, task, variables]);

  /*** Setup form ***/
  const handlers = useForm<JiraCreateTaskFormData>({
    resolver: zodResolver(jiraCreateTaskFormSchema),
    defaultValues: values,
  });

  const onSubmit = (data: JiraCreateTaskFormData) => {
    if (!task) return;

    const inputFields = applyPrefixToVariables(data.variables, 'to', 'jiraFields.').map((mapperVariable) => {
      const inputField: FieldMapping = {
        name: mapperVariable.to,
        value: mapperVariable.from,
        mappingType: 'JMESPathExpression',
      };
      return inputField;
    });

    // Add extra values BE need to know
    inputFields.push({
      name: ISSUE_TYPE_ID_NAME,
      value: data.issueType,
      mappingType: 'Value',
    });
    // Re-save, if already existed, or create some ticketId for first time
    inputFields.push();

    projectId &&
      inputFields.push({
        name: PROJECT_ID_NAME,
        value: projectId,
        mappingType: 'Value',
      });
    // TODO: Add attachments feature once it's fully supported on WF side
    // inputFields.push({
    //   name: 'includeAttachments',
    //   value: `\`${data.includeAttachments}\``,
    //   mappingType: 'JMESPathExpression',
    // });

    const ioMappingConfigurations = (task.task.configurations ?? []).filter((v) =>
      isIOMappingConfiguration(v)
    ) as TaskIOMappingConfiguration[];
    let outputFields = ioMappingConfigurations.length ? ioMappingConfigurations[0].mapping.outputFields : [];
    const testFound = outputFields.find((outputField) => outputField.name === 'ticketId');
    if (!testFound) {
      outputFields = [
        ...outputFields,
        // Check if this works so unique ticketIds are generated
        // {
        //   name: 'ticketId',
        //   value: `ticketId_${randomId().split('-')[0]}`,
        //   mappingType: 'Value',
        // },
      ];
    }

    const newTask = updateTaskIOMapping(task, {
      name: data.name,
      description: data.description,
      ioMappingConfigurations: [
        {
          kind: 'ProcessEngine:TaskIOMapping',
          version: DEFINITION_VERSION,
          mapping: {
            inputFields,
            outputFields,
          },
        },
      ],
    });

    saveTask(newTask);
  };

  return (
    <Stack height="100%">
      <FormProvider {...handlers}>
        <form style={{ height: '100%' }} onSubmit={handlers.handleSubmit(onSubmit)}>
          <Stack justifyContent="space-between" height="100%">
            <Stack gap={4} px={6} height="100%">
              {/*TODO: Rethink and refactor use of name and description fields to main schema*/}
              <EditServiceTaskForm onDirty={onDirty} />

              <IssueTypePicker defaultIssueTypeId={values.issueType} {...issueTypePickerProps} />

              {isLoadingIntegration || isLoadingIssueTypes || isFetchingIssueTypeFields ? (
                <Stack gap={2}>
                  <LoadingSkeleton variant="rounded" />
                  <LoadingSkeleton variant="rounded" />
                  <LoadingSkeleton variant="rounded" />
                  <LoadingSkeleton variant="rounded" width="40%" />
                </Stack>
              ) : (
                <VariableMapper<JiraCreateTaskFormData>
                  formFieldName="variables"
                  requiredToFieldsCount={requiredToFields.length}
                  otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.JIRA')}
                  {...variableMapperProps}
                />
              )}
              {/* TODO: Add attachments feature once it's fully supported on WF side */}
              {/* <Attachments />} */}
            </Stack>
            {isIntegrationActive && (
              <Box p={6}>
                <Text variant="body-regular" size="XS" color="inactive">
                  <Trans
                    i18nKey="INTELLIGENT_WORKFLOWS.JIRA.FOOTER"
                    components={{
                      a1: (
                        <StyledLink
                          component={RouterLink}
                          // TODO: ABSOLUTE_PATH needs to be used here, but then yarn tsc fails for admin-panel. WTF is this sh*t again?!
                          to={generatePath(routes.PREFERENCES.TAB.JIRA.PATH, { activeTab: 'integrations' })}
                        />
                      ),
                      a2: (
                        <StyledLink href="https://support.vertice.one/hc/en-us/articles/30461309531025-Jira-Integration-Set-up-guide" />
                      ),
                    }}
                  />
                </Text>
              </Box>
            )}
            <Stack direction="row" gap={1} p={6} borderTop={`1px solid ${palette.core.color3}`}>
              <Button variant="outline" size="S" color="secondary" fullWidth onClick={close}>
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.DISCARD')}
              </Button>
              <Button variant="solid" size="S" color="secondary" fullWidth type="submit">
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.APPLY')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};
