import { FC, useState } from 'react';

import {
  useGetAccountIntegrationQuery,
  useListCreateJiraIssueTypeFieldsQuery,
  useListJiraIssueTypesQuery,
} from '@vertice/slices';
import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../applications/utils';
import { useVariablesAvailableInNode } from '../../useVariablesAvailableInNode';
import { EditServiceTaskBase, JiraIssueType, JiraProject } from '../types';
import { JiraCreateForm } from './JiraCreateForm';
import { getCorrespondingTaskService } from '../JiraCommon/utils';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraCreate: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();
  const taskService = getCorrespondingTaskService(task, resources);

  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const selectedProject = integrationData?.parameters?.projects?.[0] as JiraProject | undefined;
  const { data: issueTypesData, isLoading: isLoadingIssueTypes } = useListJiraIssueTypesQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
    },
    { skip: !selectedProject }
  );

  const [selectedIssueType, setSelectedIssueType] = useState<JiraIssueType | undefined>(undefined);

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: selectedIssueType?.id ?? '',
    },
    { skip: !selectedProject?.id || !selectedIssueType?.id }
  );

  const { requestProperties, globalUDFProperties } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  const isIntegrationActive = integrationData?.status === 'ACTIVE';

  return (
    <>
      {taskService && (
        <JiraCreateForm
          task={task}
          taskService={taskService}
          projectId={selectedProject?.id ?? ''}
          isFetchingIssueTypeFields={isFetchingIssueTypeFields}
          {...restProps}
          issueTypePickerProps={{
            isIntegrationActive,
            isLoadingIntegration,
            issueTypes: issueTypesData?.issueTypes ?? [],
            isLoadingIssueTypes,
            setSelectedIssueType: setSelectedIssueType,
          }}
          variableMapperProps={{
            isIntegrationActive,
            workflowVariables: {
              global: globalUDFProperties,
              request: requestProperties,
            },
            jiraVariables: issueTypeFieldsData?.fields ?? [],
            fromWorkflow: true,
          }}
        />
      )}
    </>
  );
};
