import React from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';
import { StatBar, StatBarDivider, StatBarItem } from '@verticeone/design-system';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import { useMonitoringLatestQuery } from '../../../../dataSources/useMonitoringLatestQuery';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

const SavingStatBarItem = styled(StatBarItem)(({ theme }) => ({
  '.value': {
    color: theme.palette.tertiary.color1,
  },
}));

const Summary = () => {
  const { palette } = useTheme();
  const { data } = useMonitoringLatestQuery('Compute');
  const { t } = useTranslation(undefined, { keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS` });
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  return (
    <Stack position="relative">
      <StatBar>
        <Text
          color="text2"
          variant="caption"
          size="XS"
          bgcolor={palette.core.bg}
          position="absolute"
          left="14px"
          top="-10px"
          p={1}
        >
          {t('SUMMARY.LAST_DAYS', { count: 32 })}
        </Text>
        <StatBarItem
          title={t('SUMMARY.SAVINGS_FOUND')}
          value={
            data?.savings_found_from || data?.savings_found_to
              ? [
                  formatCurrency(data?.savings_found_from ?? 0, {
                    currency: AWS_DEFAULT_CURRENCY,
                    maximumFractionDigits: 0,
                  }),
                  formatCurrency(data?.savings_found_to ?? 0, {
                    currency: AWS_DEFAULT_CURRENCY,
                    maximumFractionDigits: 0,
                  }),
                ].join(' - ')
              : formatCurrency(0, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 })
          }
          tooltip={{
            title: t('SUMMARY.SAVINGS_FOUND'),
            content: t('SUMMARY.SAVINGS_FOUND_TOOLTIP'),
          }}
          testId="savings-found"
        />
        <StatBarDivider />
        <StatBarItem
          title={t('SUMMARY.RESOURCE_COST')}
          value={formatCurrency(data?.resource_cost ?? 0, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          })}
          tooltip={{
            title: t('SUMMARY.RESOURCE_COST'),
            content: t('SUMMARY.RESOURCE_COST_TOOLTIP', { product: t('COMPUTE.TITLE') }),
          }}
          testId="resource-cost"
        />
        <StatBarDivider />
        <StatBarItem
          title={t('SUMMARY.ON_DEMAND_RESOURCE_COST')}
          value={formatCurrency(data?.on_demand_resource_cost ?? 0, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          })}
          tooltip={{
            title: t('SUMMARY.ON_DEMAND_RESOURCE_COST'),
            content: t('SUMMARY.ON_DEMAND_RESOURCE_COST_TOOLTIP', { product: t('COMPUTE.TITLE') }),
          }}
          testId="resource-cost"
        />
        <StatBarDivider />
        <StatBarItem
          title={t('SUMMARY.RI_UTILIZATION')}
          value={formatPercentage(data?.ec2_ri_utilization_percentage ?? 0)}
          tooltip={{
            title: t('SUMMARY.RI_UTILIZATION'),
            content: t('SUMMARY.RI_UTILIZATION_TOOLTIP'),
          }}
          testId="ri-utilization"
        />
        <StatBarDivider />
        <StatBarItem
          title={t('SUMMARY.SP_UTILIZATION')}
          value={formatPercentage(data?.sp_utilization_percentage ?? 0)}
          tooltip={{
            title: t('SUMMARY.SP_UTILIZATION'),
            content: t('SUMMARY.SP_UTILIZATION_TOOLTIP', { product: t('COMPUTE.TITLE') }),
          }}
          testId="sp-utilization"
        />
        <StatBarDivider />
        <SavingStatBarItem
          title={t('SUMMARY.ACHIEVED_SAVINGS')}
          value={formatCurrency(data?.savings_achieved ?? 0, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          })}
          tooltip={{
            title: t('SUMMARY.ACHIEVED_SAVINGS'),
            content: t('SUMMARY.ACHIEVED_SAVINGS_TOOLTIP', { product: t('COMPUTE.TITLE') }),
          }}
          testId="achieved-savings"
        />
      </StatBar>
    </Stack>
  );
};

export default Summary;
