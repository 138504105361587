import dagre from 'dagre';
import { cloneDeep } from 'lodash';

import type { EdgeId, NodeId, Path, Property } from './types';
import type { ProcessDefinition } from '../../definitionsTypes';
import jmespath from '@metrichor/jmespath';

export const removeLabelPostfixFromEdgeId = (edgeId: EdgeId) => edgeId.replace(/-(start|end)$/, '');

const START_NODE_ID_FALLBACK = 'start';

export const getProcessDefinitionStartNodeId = (processDefinition: ProcessDefinition) => {
  const startNode = processDefinition.process.start[0];

  if (!startNode) {
    return START_NODE_ID_FALLBACK;
  }

  return startNode.start.id;
};

export const findAllPathsBetweenNodes = ({
  graph,
  fromNodeId,
  toNodeId,
}: {
  graph: dagre.graphlib.Graph;
  fromNodeId: NodeId;
  toNodeId: NodeId;
}): Path[] => {
  const paths: Path[] = [];
  const visited = new Set<NodeId>();

  const doDepthFirstSearch = (currentNode: NodeId, nodePath: NodeId[], edgePath: EdgeId[]) => {
    nodePath.push(currentNode);
    visited.add(currentNode);

    if (currentNode === toNodeId) {
      paths.push({ nodes: [...nodePath], edges: [...edgePath] });
    } else {
      const neighbors = (graph.successors(currentNode) || []) as unknown as NodeId[];
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          const edgeId = graph.edge(currentNode, neighbor).id;
          edgePath.push(edgeId);
          doDepthFirstSearch(neighbor, nodePath, edgePath);
          edgePath.pop();
        }
      }
    }

    nodePath.pop();
    visited.delete(currentNode);
  };

  doDepthFirstSearch(fromNodeId, [], []);
  return paths;
};

export const toJMESPathExpression = <T extends unknown>(obj: T): string => {
  if (obj === null) {
    return 'null';
  }

  if (Array.isArray(obj)) {
    return `[${obj.map(toJMESPathExpression).join(',')}]`;
  }

  if (typeof obj === 'object') {
    const entries = Object.entries(obj).map(([key, value]) => `${key}:${toJMESPathExpression(value)}`);
    return `{${entries.join(',')}}`;
  }

  return `\`${String(obj)}\``;
};

export const getObjectFromJMESPathExpression = <T extends unknown>(expression?: string): T => {
  return jmespath.search('{}', expression ? expression : '[]') as T;
};

export const sortPropertiesAlphabetically = (properties: Property[]): Property[] => {
  const clonedProperties = cloneDeep(properties);

  function sortProperties(nestedProperties: Property[]): Property[] {
    nestedProperties.sort((a, b) => a.label.localeCompare(b.label));

    for (const nestedProperty of nestedProperties) {
      if ('properties' in nestedProperty && Array.isArray(nestedProperty.properties)) {
        nestedProperty.properties = sortProperties(nestedProperty.properties);
      }
    }

    return nestedProperties;
  }

  return sortProperties(clonedProperties);
};
