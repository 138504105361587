import { defaultOperators, type Field, FullOperator, OptionList } from 'react-querybuilder';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Property } from '../../../types';

type FieldSettings = {
  operators: FullOperator[];
  inputType: Field['inputType'];
  valueEditorType: Field['valueEditorType'];
  values?: OptionList;
};

type OperatorKey = 'number' | 'boolean' | 'string' | 'date' | 'default';

type OperatorsMap = Record<OperatorKey, FullOperator[]>;

type ValueKey = 'contractOrigin' | 'status' | 'rollingFrequency' | 'vendorType';

type ValuesMap = Record<ValueKey, OptionList>;

const arraysHaveIntersection = (array1?: string[], array2?: string[]) => {
  if (!array1 || !array2) {
    return false;
  }

  const set2 = new Set(array2);
  return array1.some((element) => set2.has(element));
};

const getFieldSettingsForVariable = ({
  variable,
  values,
  operators,
}: {
  variable: Property;
  operators: OperatorsMap;
  values: ValuesMap;
}): FieldSettings => {
  if (arraysHaveIntersection(variable.typeLabel, ['Number', 'Amount'])) {
    return {
      operators: operators.number,
      inputType: 'number',
      valueEditorType: 'text',
    };
  }

  if (arraysHaveIntersection(variable.typeLabel, ['Boolean'])) {
    return {
      operators: operators.boolean,
      inputType: 'checkbox',
      valueEditorType: 'checkbox',
    };
  }

  if (arraysHaveIntersection(variable.typeLabel, ['String'])) {
    if (variable.id === 'contractOrigin') {
      return {
        operators: operators.boolean,
        inputType: 'text',
        valueEditorType: 'select',
        values: values.contractOrigin,
      };
    }

    return {
      operators: operators.string,
      inputType: 'text',
      valueEditorType: 'text',
    };
  }

  if (arraysHaveIntersection(variable.typeLabel, ['Date'])) {
    return {
      operators: operators.date,
      inputType: 'date',
      valueEditorType: 'text',
    };
  }

  if (arraysHaveIntersection(variable.typeLabel, ['Enum'])) {
    if (variable.id === 'status') {
      return {
        operators: operators.boolean,
        inputType: 'text',
        valueEditorType: 'select',
        values: values.status,
      };
    }

    if (variable.id === 'contractOrigin') {
      return {
        operators: operators.boolean,
        inputType: 'text',
        valueEditorType: 'select',
        values: values.contractOrigin,
      };
    }

    if (variable.id.endsWith('vendor.type')) {
      return {
        operators: operators.boolean,
        inputType: 'text',
        valueEditorType: 'select',
        values: values.vendorType,
      };
    }

    if (variable.id.endsWith('rollingFrequency')) {
      return {
        operators: operators.boolean,
        inputType: 'text',
        valueEditorType: 'select',
        values: values.rollingFrequency,
      };
    }
  }

  return {
    operators: operators.default,
    inputType: 'text',
    valueEditorType: 'text',
  };
};

const createFlatOptionListFromVariables = ({
  variables,
  values,
  operators,
}: {
  variables: Property[];
  operators: OperatorsMap;
  values: ValuesMap;
}): Field[] => {
  const optionList: Field[] = [];

  const processVariable = (variable: Property) => {
    optionList.push({
      id: variable.id,
      name: variable.id,
      label: variable.label,
      ...getFieldSettingsForVariable({ variable, operators, values }),
    });

    if ('properties' in variable && Array.isArray(variable.properties)) {
      for (const property of variable.properties) {
        processVariable(property);
      }
    }
  };

  for (const variable of variables) {
    processVariable(variable);
  }

  return optionList;
};

const useLocalizedOperators = (): OperatorsMap => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.OPERATORS',
  });

  return useMemo(
    () => ({
      number: [
        { label: t('NUMBER.EQUAL'), value: '=', name: '=' },
        { label: t('NUMBER.NOT_EQUAL'), value: '!=', name: '!=' },
        { label: t('NUMBER.GREATER_THAN'), value: '>', name: '>' },
        { label: t('NUMBER.LESS_THAN'), value: '<', name: '<' },
        { label: t('NUMBER.GREATER_THAN_OR_EQUAL'), value: '>=', name: '>=' },
        { label: t('NUMBER.LESS_THAN_OR_EQUAL'), value: '<=', name: '<=' },
      ],
      boolean: [
        { label: t('BOOLEAN.EQUAL'), value: '=', name: '=' },
        { label: t('BOOLEAN.NOT_EQUAL'), value: '!=', name: '!=' },
      ],
      date: [
        { label: t('DATE.EQUAL'), value: '=', name: '=' },
        { label: t('DATE.NOT_EQUAL'), value: '!=', name: '!=' },
        { label: t('DATE.GREATER_THAN'), value: '>', name: '>' },
        { label: t('DATE.LESS_THAN'), value: '<', name: '<' },
        { label: t('DATE.GREATER_THAN_OR_EQUAL'), value: '>=', name: '>=' },
        { label: t('DATE.LESS_THAN_OR_EQUAL'), value: '<=', name: '<=' },
      ],
      string: [
        { label: t('STRING.EQUAL'), value: '=', name: '=' },
        { label: t('STRING.NOT_EQUAL'), value: '!=', name: '!=' },
        { label: t('STRING.CONTAINS'), value: 'contains', name: 'contains' },
        { label: t('STRING.DOES_NOT_CONTAIN'), value: 'doesNotContain', name: 'doesNotContain' },
        { label: t('STRING.BEGINS_WITH'), value: 'beginsWith', name: 'beginsWith' },
        { label: t('STRING.DOES_NOT_BEGIN_WITH'), value: 'doesNotBeginWith', name: 'doesNotBeginWith' },
        { label: t('STRING.ENDS_WITH'), value: 'endsWith', name: 'endsWith' },
        { label: t('STRING.DOES_NOT_END_WITH'), value: 'doesNotEndWith', name: 'doesNotEndWith' },
      ],
      default: defaultOperators,
    }),
    [t]
  );
};

const useLocalizedValues = (): ValuesMap => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.VALUES',
  });

  return useMemo(
    () => ({
      contractOrigin: [
        {
          label: t('CONTRACT_ORIGIN.RENEWAL_WITH_VERTICE'),
          value: 'RENEWAL_WITH_VERTICE',
          name: 'RENEWAL_WITH_VERTICE',
        },
        {
          label: t('CONTRACT_ORIGIN.RENEWAL_WITHOUT_VERTICE'),
          value: 'RENEWAL_WITHOUT_VERTICE',
          name: 'RENEWAL_WITHOUT_VERTICE',
        },
        {
          label: t('CONTRACT_ORIGIN.PURCHASE_WITH_VERTICE'),
          value: 'PURCHASE_WITH_VERTICE',
          name: 'PURCHASE_WITH_VERTICE',
        },
        {
          label: t('CONTRACT_ORIGIN.PURCHASE_WITHOUT_VERTICE'),
          value: 'PURCHASE_WITHOUT_VERTICE',
          name: 'PURCHASE_WITHOUT_VERTICE',
        },
        { label: t('CONTRACT_ORIGIN.EXISTING'), value: 'EXISTING', name: 'EXISTING' },
        { label: t('CONTRACT_ORIGIN.UNKNOWN'), value: 'UNKNOWN', name: 'UNKNOWN' },
      ],
      status: [
        { label: t('STATUS.REPEAT'), value: 'REPEAT', name: 'REPEAT' },
        { label: t('STATUS.DECLINE'), value: 'DECLINE', name: 'DECLINE' },
        { label: t('STATUS.ACCEPTED'), value: 'ACCEPTED', name: 'ACCEPTED' },
      ],
      rollingFrequency: [
        { label: t('ROLLING_FREQUENCY.MONTHLY'), value: 'MONTHLY', name: 'MONTHLY' },
        { label: t('ROLLING_FREQUENCY.QUARTERLY'), value: 'QUARTERLY', name: 'QUARTERLY' },
        { label: t('ROLLING_FREQUENCY.BI_ANNUALLY'), value: 'BIANNUALLY', name: 'BIANNUALLY' },
        { label: t('ROLLING_FREQUENCY.ANNUALLY'), value: 'ANNUALLY', name: 'ANNUALLY' },
        { label: t('ROLLING_FREQUENCY.NO'), value: 'NO', name: 'NO' },
      ],
      vendorType: [
        { label: t('VENDOR.TYPE.PREDEFINED'), value: 'PREDEFINED', name: 'PREDEFINED' },
        { label: t('VENDOR.TYPE.INLINE'), value: 'INLINE', name: 'INLINE' },
      ],
    }),
    [t]
  );
};

export const useFieldsFromVariables = (variables: Property[]): Field[] => {
  const localizedOperators = useLocalizedOperators();
  const localizedValues = useLocalizedValues();

  return useMemo(
    () => createFlatOptionListFromVariables({ variables, operators: localizedOperators, values: localizedValues }),
    [variables, localizedOperators, localizedValues]
  );
};
