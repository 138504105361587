import { useMemo } from 'react';
import { PredefinedFormDef } from './predefinedForms/shared/types';
import { formDef as approvalSimpleFormDef } from './predefinedForms/ApprovalSimpleForm/formDef';
import { formDef as approvalSimpleFormDefSaaS } from './predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { formDef as purchaseIntakeSimpleFormDef } from './predefinedForms/PurchaseIntakeSimpleForm/formDef';
import { formDef as requirementsGatheringFormDef } from './predefinedForms/RequirementsGatheringForm/formDef';
import { formDef as assignRequestOwnerFormDef } from './predefinedForms/AssignRequestOwner/formDef';
import { formDef as contractingForm } from './predefinedForms/ContractingForm/formDef';
import { formDef as customerNegotiationFormDef } from './predefinedForms/CustomerLedNegotiation/formDef';
import { formDef as renewalRequirementsForm } from './predefinedForms/RenewalRequirementsForm/formDef';
import { formDef as baselineOfferForm } from './predefinedForms/BaselineOfferForm/formDef';
import { formDef as negotiationOfferForm } from './predefinedForms/NegotiationOfferForm/formDef';
import { formDef as finalOfferForm } from './predefinedForms/FinalOfferForm/formDef';
import { formDef as simpleStepOnForm } from './predefinedForms/SimpleStepOnForm/formDef';
import { formDef as securityQuestionnaireForm } from './predefinedForms/SecurityQuestionnaireForm/formDef';
import { formDef as securityReviewForm } from './predefinedForms/SecurityAssessmentForm/formDef';
import { formDef as securityApprovalForm } from './predefinedForms/SecurityApprovalForm/formDef';
import { formDef as genericForm } from './predefinedForms/GenericForm/formDef';

const availableFormsDefs: PredefinedFormDef<any>[] = [
  approvalSimpleFormDef,
  approvalSimpleFormDefSaaS,
  purchaseIntakeSimpleFormDef,
  requirementsGatheringFormDef,
  assignRequestOwnerFormDef,
  contractingForm,
  customerNegotiationFormDef,
  renewalRequirementsForm,
  baselineOfferForm,
  negotiationOfferForm,
  finalOfferForm,
  simpleStepOnForm,
  securityQuestionnaireForm,
  securityReviewForm,
  securityApprovalForm,
  genericForm,
];

export const usePredefinedFormDef = (formConfig: { [p: string]: any }) => {
  return useMemo(
    () =>
      availableFormsDefs.find(({ urnSuffix }) => {
        const lastPart = formConfig.formUrn.split(':').pop() || formConfig.formUrn;
        if (!lastPart) return false;
        return urnSuffix instanceof RegExp ? urnSuffix.test(lastPart) : urnSuffix === lastPart;
      }) ?? undefined,
    [formConfig.formUrn]
  );
};
