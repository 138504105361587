import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { getTaskServiceConfiguration, isIOMappingConfiguration } from '../../../../definitions/taskDefinition';
import { FieldMapping, TaskDefinition } from '../../../../definitionsTypes';
import { Property, PropertyOrigin, PropertyType } from '../../types';
import { VariableMapperRowFormBase } from './VariableMapper/formSchema';
import { DEFAULT_MAPPING_TYPE } from './VariableMapper/VariableMapper';
import { JiraIssueField } from './types';

export const ISSUE_TYPE_ID_NAME = 'issueTypeId';
export const PROJECT_ID_NAME = 'projectId';
export const TICKET_ID_NAME = 'ticketId';

/**
 * Get Input mapping variables  used in  Workflow to Create Jira mapping
 * Jira ids are internally prefixed, we need to strip that prefix in preparation for used in variable picker
 * @param task
 * @param taskService
 */
export const getJiraCreateInputMapping = (task: TaskDefinition, taskService: ServiceCatalogResource) => {
  // get patter BE is expecting the jira fields have - like: "^jiraFields."
  const pattern = Object.keys(taskService.definition.ServiceProvider.Interface.Input.JsonSchema.patternProperties)[0];
  // eslint-disable-next-line no-useless-escape
  const escapedPattern = pattern.replace('.', `\.`); // Escape dot we need to keep

  let projectId: string | undefined;
  let issueTypeId: string | undefined;
  let includeAttachments: boolean | undefined;
  let variables: FieldMapping[] = [];
  let ticketId: string | undefined;

  const ioMappingConfiguration = task.task.configurations?.find(isIOMappingConfiguration);
  const inputFields = ioMappingConfiguration?.mapping.inputFields;

  if (ioMappingConfiguration && inputFields) {
    inputFields.forEach((field) => {
      // required field we need to know
      if (field.name === PROJECT_ID_NAME) {
        projectId = field.value;
        // required field we need to know
      } else if (field.name === ISSUE_TYPE_ID_NAME) {
        issueTypeId = field.value;
      } else if (field.name === TICKET_ID_NAME) {
        ticketId = field.value;
      } else if (field.name === 'includeAttachments') {
        includeAttachments = field.value === 'true';
      } else {
        const regex = new RegExp(`${escapedPattern}(.*)`); // Capturing group for the rest of the string
        const match = field.name.match(regex);
        if (match) {
          variables.push({ ...field, name: match[1] });
        } else {
          variables.push(field);
        }
      }
    });
  }
  return {
    projectId,
    issueTypeId,
    includeAttachments,
    variables,
    ticketId,
  };
};

/**
 * Map workflow type variables into object used in variable mapper
 */
export const workflowToFormVariables = (workflowVariables: FieldMapping[]) => {
  const formVariables: VariableMapperRowFormBase[] = workflowVariables.map((inputField) => ({
    from: inputField.value,
    to: inputField.name,
    mappingType: inputField.mappingType,
  }));

  return formVariables;
};

export const applyPrefixToVariables = (
  variables: VariableMapperRowFormBase[],
  key: keyof VariableMapperRowFormBase,
  prefix: string
) => {
  return variables.map((variable) => {
    return { ...variable, [key]: `${prefix}${variable[key]}` };
  });
};

export const enhanceWithDefaultDestination = (
  formVariables: VariableMapperRowFormBase[],
  requiredFieldIds: string[]
) => {
  const notUsedFieldIds = requiredFieldIds.filter(
    (requiredFieldId) => !formVariables.some((variable) => variable.to === requiredFieldId)
  );
  const newDefaults: VariableMapperRowFormBase[] = notUsedFieldIds.map((toId) => ({
    from: '',
    to: toId,
    mappingType: DEFAULT_MAPPING_TYPE,
  }));

  return [...newDefaults, ...formVariables];
};

export const getCorrespondingTaskService = (task: TaskDefinition, resources: ServiceCatalogResource[]) => {
  const taskServiceConfiguration = getTaskServiceConfiguration(task);
  return resources.find((service) => service.urn === taskServiceConfiguration?.resourceUrn);
};

// Theese mappings are just to make it through and will evolve on evolving work with jira types.
const transformJiraTypeToWorkflow = (type: string): PropertyType[] => {
  return [type as PropertyType]; // Temporary faking. When typing is worked out - update
};
// Theese mappings are just to make it through and will evolve on evolving work with jira types.
const transformWorkflowTypeToJira = (types?: PropertyType[]) => {
  return types?.[0] || '';
};

type ExtendedProperty = Property & { required: boolean; key: string };

export const transformWorkflowLikeToJiraVariable = (property: Property): JiraIssueField | undefined => {
  if (!property) return;
  const extendedProperty = property as ExtendedProperty;
  const jiraVariable: JiraIssueField & {
    typeLabel?: string[];
    origin?: PropertyOrigin;
    properties?: Property[];
    isVisible?: boolean;
  } = {
    ...extendedProperty,
    type: transformWorkflowTypeToJira(extendedProperty.type),
    name: extendedProperty.label,
  };
  delete jiraVariable['typeLabel'];
  delete jiraVariable['origin'];
  delete jiraVariable['properties'];
  delete jiraVariable['isVisible'];

  return jiraVariable;
};

export const transformJiraToWorkflowLikeVariable = (jiraVariable?: JiraIssueField): Property | undefined => {
  if (!jiraVariable) return;

  const transformedTypes = transformJiraTypeToWorkflow(jiraVariable.type);
  const wfVariable: Property & { name?: string } = {
    ...jiraVariable,
    label: jiraVariable.name,
    type: transformedTypes,
    typeLabel: transformedTypes,
    isVisible: true,
    origin: {
      // How origin should be extended? But it's only for picker, would be thrown away on processing.
      id: 'jira',
      label: 'Jira',
      kind: 'vertice task',
    },
  };
  delete wfVariable['name'];
  return wfVariable;
};
export const transformJiraToWorkflowLikeVariables = (variables: JiraIssueField[]): Property[] => {
  return variables
    .map((jiraVariable) => transformJiraToWorkflowLikeVariable(jiraVariable))
    .filter((v) => !!v) as Property[];
};
