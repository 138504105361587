import { Divider, Stack } from '@mui/material';
import React from 'react';
import Graph from './Graph';
import RecommendedTable from '../../components/BuySPDrawer/BuySPDrawerTable/RecommendedTable';
import {
  BuySPDrawer,
  BuySPDrawerHeader,
  BuySPDrawerGraph,
  BuySPDrawerTabs,
  BuySPDrawerTab,
  // CustomizeTable,
  // ShoppingTable,
} from '../../components/BuySPDrawer';
import InstanceProvider from '../../components/providers/InstanceProvider';
import { useMonitoringLatestQuery } from '../../dataSources/useMonitoringLatestQuery';
import { useDataSource } from '../../../DataSourceProvider';
import { useSPListOfferingsQuery } from '../../dataSources/useSPListOfferingsQuery';
import { parseSpOfferings } from '../../dataSources/utils';
import OfferingsProvider from '../../components/providers/OfferingsProvider';

const BuySPDrawerLayout = () => {
  const { datasource } = useDataSource();
  const { data } = useMonitoringLatestQuery(datasource);
  const getOfferings = useSPListOfferingsQuery();

  return (
    <InstanceProvider item={data!} getOfferings={getOfferings} offeringsParser={parseSpOfferings}>
      <BuySPDrawer>
        <BuySPDrawerHeader />
        <Stack pb={8} px={8}>
          <OfferingsProvider
            defaultFilter={{
              offeringType: 'No Upfront',
              duration: 31536000,
            }}
          >
            <BuySPDrawerGraph chart={Graph} />
          </OfferingsProvider>
        </Stack>
        <Divider />
        <BuySPDrawerTabs>
          <BuySPDrawerTab tabId="RECOMMENDED">
            <OfferingsProvider
              defaultFilter={{
                offeringType: 'No Upfront',
                duration: 31536000,
              }}
            >
              <RecommendedTable />
            </OfferingsProvider>
          </BuySPDrawerTab>
          {/* todo to be implemented in https://vertice.atlassian.net/browse/PINK-697
          <BuySPDrawerTab tabId="CUSTOMIZE" filterByShoppingCart>
            <OfferingsProvider>
              <CustomizeTable />
            </OfferingsProvider>
            <OfferingsProvider>
              <ShoppingTable />
            </OfferingsProvider>
          </BuySPDrawerTab>
          */}
        </BuySPDrawerTabs>
      </BuySPDrawer>
    </InstanceProvider>
  );
};

export default BuySPDrawerLayout;
