import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Button as MuiButton, Stack } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';
import { IconWrapper, testProps, type TestProps, Text, ifExpression } from '@verticeone/design-system';

import { TypeBadge, StyledTypeBadge } from './TypeBadge';
import { highlightText } from './HighlightedText';

const EllipsisText = styled(Text)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '1rem',
});

type StyledMuiButtonProps = {
  $isInline: boolean;
  $isSelected: boolean;
  $isActive: boolean;
  $isDisabled: boolean;
};

const StyledMuiButton = styled(MuiButton)<StyledMuiButtonProps>(
  ({
    theme: {
      palette: { secondary, inactive, neutral, transparent },
    },
    $isInline = false,
    $isSelected = false,
    $isActive = true,
    $isDisabled = false,
  }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    color: $isActive ? secondary[$isSelected ? 'color1' : 'color4'] : neutral.color1,
    gap: 4,
    padding: $isInline ? '2px 3px' : 6,
    borderRadius: $isInline ? 4 : 8,
    minWidth: 'unset',
    maxWidth: '100%',
    '&&': {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: $isInline ? 4 : 8,
        backgroundColor: $isActive ? secondary[$isSelected ? 'color4' : 'color2'] : 'auto',
        opacity: $isSelected ? 0.4 : 1,
        zIndex: -1,
        ...ifExpression($isDisabled, {
          backgroundColor: $isActive ? inactive.color4 : 'auto',
        }),
      },
    },
    [`& ${StyledTypeBadge}`]: {
      backgroundColor: $isActive ? ($isSelected ? secondary.color4 : transparent.color4) : neutral.color4,
      color: $isActive ? secondary[$isSelected ? 'color1' : 'color4'] : neutral.color2,
    },
    ...ifExpression($isDisabled, {
      color: $isActive ? inactive.color1 : inactive.color2,
      [`& ${StyledTypeBadge}`]: {
        backgroundColor: $isActive ? inactive.color3 : inactive.color4,
        color: inactive.color2,
      },
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      color: $isActive ? secondary.hover[$isSelected ? 'color1' : 'color4'] : secondary.color1,
      '&&': {
        '&::before': {
          backgroundColor: $isActive ? secondary.hover[$isSelected ? 'color4' : 'color2'] : secondary.color4,
        },
      },
      [`& ${StyledTypeBadge}`]: {
        backgroundColor: $isActive ? ($isSelected ? secondary.color4 : transparent.color4) : secondary.color4,
        color: $isActive ? secondary.hover[$isSelected ? 'color1' : 'color4'] : secondary.color1,
      },
    },
    '&:focus-visible, &:active': {
      color: $isActive ? secondary[$isSelected && !$isInline ? 'color1' : 'color4'] : secondary.color1,
      '&&': {
        '&::before': {
          opacity: $isSelected && !$isInline ? 0.4 : 1,
          backgroundColor: $isActive ? secondary[$isSelected && !$isInline ? 'color4' : 'color2'] : secondary.color4,
          outline: `2px solid ${secondary[$isSelected && !$isInline ? 'color3' : 'color4']}`,
        },
      },
      [`& ${StyledTypeBadge}`]: {
        backgroundColor: $isActive
          ? $isSelected && !$isInline
            ? secondary.color4
            : transparent.color4
          : secondary.color4,
        color: $isActive ? secondary[$isSelected && !$isInline ? 'color1' : 'color4'] : secondary.color1,
      },
    },
  })
);

type VariableChipProps = TestProps & {
  isInline?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  label?: string;
  typeLabel?: string;
  onClick?: () => void;
  withIcon?: boolean;
  highlightedLabel?: string;
};

export const VariableChip: FC<VariableChipProps> = ({
  label,
  isInline = false,
  isDisabled = false,
  isActive = true,
  withIcon = false,
  typeLabel,
  onClick,
  testId,
  highlightedLabel = '',
}) => {
  const { t } = useTranslation();

  const isSelected = !!label;

  const labelBase = label || t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.ACTIONS.SELECT_VARIABLE');
  const finalLabel = highlightText(labelBase, highlightedLabel);

  return (
    <StyledMuiButton
      {...testProps(testId, 'variable-chip')}
      $isInline={isInline}
      $isSelected={isSelected}
      $isActive={isActive}
      $isDisabled={isDisabled}
      onClick={onClick}
      role="button"
    >
      <EllipsisText size="S" variant={isActive ? 'button-bold' : 'button-regular'}>
        {finalLabel}
      </EllipsisText>
      {typeLabel && <TypeBadge highlightedLabel={highlightedLabel} label={typeLabel} />}
      {withIcon && (
        <Stack sx={{ marginLeft: 'auto' }}>
          <IconWrapper
            size="XS"
            sx={{
              opacity: 0.6,
            }}
            icon={OpenInNewOutlined}
          />
        </Stack>
      )}
    </StyledMuiButton>
  );
};
